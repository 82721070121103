import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useHref, useNavigate, Navigate } from 'react-router-dom';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import Header from './Header'
import { contactsValidation } from './contactsValidation';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import $ from 'jquery';
import 'datatables.net-bs4';
import { differenceInYears } from 'date-fns';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Button } from 'react-bootstrap';


const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Fetch existing leads
const fetchLeads = async () => {
    const response = await axios.get(`${apiUrl}/leads-list`); // Replace with your API
    console.log("Lead list for dropdown", response.data.data);
    return response.data.data; // Return data directly
  };
  
  // Add new lead API call
  const addLead = async (newLead) => {
    const response = await axios.post(`${apiUrl}/add-leads-form-deals`, newLead); // Replace with your API
    return response.data;
  };

  // Validation schema for the form
  const validationSchema = Yup.object({
    lead_first_name: Yup.string().required('First name is required'),
    lead_mobile: Yup.string().required('Mobile number is required'),
    lead_email: Yup.string().email('Invalid email address').required('Email is required'),
  });





const Deals = () => {
 
const queryClient = useQueryClient(); // For cache management
 
 // Fetch existing leads using useQuery
 const { data: leadsData, isLoading, error, refetch } = useQuery({
    queryKey: ['newLeadsList'],
    queryFn: fetchLeads,
  });

  

  // Mutation for adding a new lead
  const mutation = useMutation({
    mutationFn: addLead,
    onSuccess: (newLead) => {
      // Update the leads list without refreshing by invalidating the query or updating cache manually
      queryClient.setQueryData(['newLeadsList'], (oldData) => ({
        ...oldData,
        data: [...oldData.data, newLead], // Append new lead to the existing leads
      }));
    },
  });

  // State for modal and form visibility
  const [show, setShow] = useState(false);

  // Open modal for adding new lead
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  
    
    // a local state to store the currently selected file.
    const [inputValue]                           = useState('');
    const [selectedDate, setSelectedDate]        = useState(null);
    const [date, setDate]                        = useState(new Date());
    const [contacts, setContacts]                = useState([]);
    const [leads, setLeads]                      = useState([]);

    const [products, setProducts]                = useState([]);
    const [assignees, setAssignees]                = useState([]);


    const [selectedContact, setSelectedContact]  = useState('');


    const [states, setStates]                    = useState([]);
    const [cities, setCities]                    = useState([]);
    const [selectedState, setSelectedState]      = useState('');
    const [contactList, setContactList]          = useState([]);
    const [data, setData]                        = useState([]);
    const [editDeals, setEditDeals]          = useState([]);
    const [statusContact, setStatusContact]      = useState('');
    const [selectedValue, setSelectedValue]      = useState('');
    const [gender, setGender]                    = useState('');
    const [selectedFile, setSelectedFile]        = React.useState(null);
    const [selectedFileAdd, setSelectedFileAdd]  = React.useState(null);
    const [totalCount, setTotalCount]            = useState(0);
    const [isVisible, setIsVisible]              = useState(false);
    const [image2, setImage2]                    = useState(null);
    const [selectedValues, setSelectedValues]    = useState([]);

    const [hiddenEmpIdValue, setHiddenEmpIdValue]= useState(null);
    const [showModal, setShowModal]              = useState(false);
    const [formValues, setFormValues]            = useState('');
    const modalRef                               = useRef(null);




    const navigate = useNavigate();

    $("#categorieslist_data_wrapper").off().on("click", '.edit-popup', function () {
        alert('edit id')
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        const id = $(this).attr('data-id');
        handleEdit(id);
        return false;
    });

    const handleEdit = async (id) => {
        alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/deals-edit`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    setEditDeals(response.data.data);
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };


    // Event handler for status action
    $("#categorieslist_data").off().on("click", '.statusaction', function () {
        const statusActionId = $(this).attr('status-action-data-id');
        const statusAction = $(this).attr('status-action');
        // Confirmation dialog

        const userConfirmed = window.confirm("Are you sure you want to proceed?");

        if (userConfirmed) {
            try {
                handleStatusAction(statusActionId, statusAction);
            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


        return false;
    });
    

    const handleStatusAction = async (id, statusAction) => {
        if (id) {
            try {
                const response = await axios.post(`${apiUrl}/deals-status`, {
                    id: `${id}`,
                    statusAction: `${statusAction}`
                });

                // Update the specific row in the DataTable's data set without reloading
                if (response.data && response.data.data) {
                    updateDataTableRow(id, response.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };

// Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/deals-contact-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setContacts(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/leads-list-for-deals`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setLeads(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);

     // Fetch countries data
     useEffect(() => {

        axios.post(`${apiUrl}/deals-product-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setProducts(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


   
     // Fetch countries data
     useEffect(() => {

        axios.post(`${apiUrl}/deals-assignee-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setAssignees(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


   

    // Contact List data
    useEffect(() => {

        axios.post(`${apiUrl}/dealslist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "deal_id" },
                                { title: "Deal Name", data: "deal_name" },
                                { title: "Pipeine", data: "pipeine" },
                                { title: "Deal Value", data: "deal_value" },
                                { title: "Currency", data: "currency" },
                                { title: "Due Date", data: "due_date" },
                                { title: "Expected Closing Date", data: "expected_closing_date" },
                                { title: "Deals Status", data: "deals_status" },
                                // { title: "Deal Price", data: "deal_price" },
                                { title: "Priority", data: "priority" },
                                {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        console.log("status============", statusContact);
                                        if (row.is_active == 1) {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.deal_id}" status-action="0"><span class='badge badge-pill badge-status bg-success'>Active</span></a>`;
                                        } else {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.deal_id}" status-action="1"><span class='badge badge-pill badge-status bg-danger'>InActive</span></a>`;

                                        }
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                                        <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.deal_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                                        <a class="dropdown-item detail-data"  href="javascript:void(0);" detail-data-id="${row.deal_id}"><i class="ti ti-eye text-blue-light"></i> View</a>
                                        </div></div>`;

                                       
                                    }
                                },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    const handleGetValue = () => {
        // Access the value of the input element
        //alert('dddd');
    };



    const handleClick = (id, event) => {
        event.preventDefault();
        console.log('Clicked ID:', id);
        alert('dddd');
        // Additional logic here
    };


    const handleOnSubmit = (values) => {
        const first_name = Object.keys(values)
            .map((key) => values[key])
            .join(" ");
        alert(`Hello ${first_name}!`);
    };



   

    const setSelectValue = (field, value) => {
        alert(value);
        setFormValues({
            ...formValues,
            [field]: value,
        });
    };


    const handleImage2Change = (e) => {
        setImage2(e.target.files[0]);
    };



   //############# ADD FORM & VALIDATION START ########################################//

    const schema = Yup.object().shape({
        deal_name: Yup.string().min(3).required('Deal name is required'),
        pipeine: Yup.string().required('Please select pipeine'),
        deals_status: Yup.string().required('Please select status'),
        currency: Yup.string().required('Please select currency'),
    });
    const formik = useFormik({
        initialValues: {
            lead_id                                   : '',
            deal_name                                 : '',
            pipeine                                   : '',
            deals_status                              : '',
            deal_value                                : '',
            currency                                  : '',
            contact                                   : '',
            product                                   : '',
            due_date                                  : '',
            expected_closing_date                     : '',
            assignee                                  : '',
            priority                                  : '',
            description                               : '',
        },
        validationSchema: schema,
        enableReinitialize: true,
        //onSubmit: handleOnSubmit,
        onSubmit: (values) => {
            
            console.log(formik.values)

            //return false;

            const formData = new FormData();
            //console.log("====contacts=========", contacts);
            // return false;
            formData.append("lead_id", values.lead_id);
            formData.append("deal_name", values.deal_name);
            formData.append("pipeine", values.pipeine);
            formData.append("deals_status", values.deals_status);
            formData.append("deal_value", values.deal_value);
            formData.append("currency", values.currency);
            formData.append("contact", values.contact);
            formData.append("product", values.product);
            formData.append("due_date", values.due_date);
            formData.append("expected_closing_date", values.expected_closing_date);
            formData.append("assignee", values.assignee);
            formData.append("priority", values.priority);
            formData.append("description", values.description);
           

            axios({
                method: 'POST',
                headers: { "Content-Type": "multipart/form-data" },
                url: `${apiUrl}/add-deals`,
                data: formData
            })
                .then(function (res) {
                    console.log(res.status)
                    if (res.status === 200) {
                        pageLoadData();
                        //$('#create_contact').addClass('d-block');
                        alert('Successfully added data');

                        $('.toggle-popup').removeClass('sidebar-popup');
                        $('.sidebar-popoverlay').removeClass('open');
                    }

                })
                .catch(function (res) {
                    console.log(res)
                });


        }

    });

    
      

   

    const pageLoadData = async () => {
        axios.post(`${apiUrl}/dealslist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "deal_id" },
                                { title: "Deal Name", data: "deal_name" },
                                { title: "Pipeine", data: "pipeine" },
                                { title: "Deal Value", data: "deal_value" },
                                { title: "Currency", data: "currency" },
                                { title: "Due Date", data: "due_date" },
                                { title: "Expected Closing Date", data: "expected_closing_date" },
                                { title: "Deals Status", data: "deals_status" },
                                // { title: "Deal Price", data: "deal_price" },
                                { title: "Priority", data: "priority" },
                                {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        return `<span class='badge badge-pill badge-status bg-success'>Active</span>`;
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                              <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.deal_id}"><i class="ti ti-edit text-blue"></i> Edit</a>a class="dropdown-item change-pass" href="javascript:void(0);" change-pass-data-id="${row.deal_id}"><i class="ti ti-key text-danger"></i> Change Password</a>
                              <a class="dropdown-item detail-data"  href="javascript:void(0);" detail-data-id="${row.deal_id}" ><i class="ti ti-eye text-blue-light"></i> View</a>
                              </div></div>`;

                                      

                                    }
                                },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the contact list data!", error);
            });

    };






    const handleContactsNavigation = () => {
        navigate('/contacts');
    };

    const setInputValue = useCallback(
        (key, value) =>
            formik.setValues({
                ...formik.values,
                [key]: value,
            }),
        [formik]
    );



    const handleChange = (event) => {
        alert(event.target.value);
        setInputValue(event.target.value);
    };







    //######################################################## Edit Section Here ###############################//

    useEffect(() => {
        // Initialize the gender state with the value from editDeals
        setGender(editDeals.gender);
    }, [editDeals]);

    useEffect(() => {
        // alert(editDeals.country_id)
        setSelectedValue(editDeals.country_id); // Dynamically set the default value
    }, []);


    useEffect(() => {

        if (editDeals) {
            setSelectedContact(editDeals.country_id);
            //setSelectedState(editDeals.state_id); 

            edit_formik.setValues({
                edit_lead_id: editDeals.fk_lead_id || '',
                edit_deal_id: editDeals.deal_id || '',
                edit_deal_id1: editDeals.deal_id || '',
                edit_deal_name: editDeals.deal_name || '',
                edit_pipeine: editDeals.pipeine || '',
                edit_product_id: editDeals.product_id || '',
                edit_deal_value: editDeals.deal_value || '',
                edit_currency: editDeals.currency || '',
                edit_contact: editDeals.contact || '',
                edit_due_date: editDeals.due_date || '',
                edit_deals_status: editDeals.deals_status || '',
                edit_deal_price: editDeals.deal_price || '',
                edit_expected_closing_date: editDeals.expected_closing_date || '',
                edit_assignee: editDeals.assignee || '',
                edit_priority: editDeals.priority || '',
                edit_description: editDeals.description || '', 
            });
        }
    }, [editDeals]);


    // Validation schema using Yup
    const edit_schema = Yup.object({
        edit_deal_name: Yup.string().min(3).required('Deal name is required'),
        edit_pipeine: Yup.string().required('Please select pipeine'),
        edit_deals_status: Yup.string().required('Please select status'),
        edit_currency: Yup.string().required('Please select currency'),

    });

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }


    // Formik setup
    const edit_formik = useFormik({
        initialValues: {
            edit_lead_id                  : '',
            edit_deal_id                  : '',
            edit_deal_id1                 : '',
            edit_deal_name                : '',
            edit_pipeine                  : '',
            edit_product_id               : '',
            edit_deal_value               : '',
            edit_currency                 : '',
            edit_contact                  : '',
            edit_due_date                 : '',
            edit_deals_status             : '',
            edit_deal_price               : '',
            edit_expected_closing_date    : '',
            edit_assignee                 : '',
            edit_priority                 : '',
            edit_description              : '', 
            
        },
        validationSchema: edit_schema,
        onSubmit: (values) => {
            console.log('Form submitted!');
           
            const formData = new FormData();
            formData.append("edit_lead_id", values.edit_lead_id);
            formData.append("edit_deal_id", values.edit_deal_id);
            formData.append("edit_deal_name", values.edit_deal_name);
            formData.append("edit_pipeine", values.edit_pipeine);

            formData.append("edit_product_id", values.edit_product_id);
            formData.append("edit_deal_value", values.edit_deal_value);
            formData.append("edit_currency", values.edit_currency);
            formData.append("edit_contact", values.edit_contact);
            formData.append("edit_due_date", values.edit_due_date);
            formData.append("edit_deals_status", values.edit_deals_status);
            
            formData.append("edit_deal_price", values.edit_deal_price);
            formData.append("edit_expected_closing_date", values.edit_expected_closing_date);
            formData.append("edit_assignee", values.edit_assignee);
            formData.append("edit_priority", values.edit_priority);
            formData.append("edit_description", values.edit_description);

            axios({
                method: 'POST',
                url: `${apiUrl}/deal-update`,
                headers: { "Content-Type": "multipart/form-data" },
                //data: values
                data: formData,
            })
                .then(function (res) {
                    console.log(res)
                    alert('Data was successfully updated.');
                    $('.toggle-popup1').removeClass('sidebar-popup');
                    $('.sidebar-popoverlay').removeClass('open');
                    editDataTableRow(values.edit_deal_id, res.data.data)
                    // $("#preview1").show();
                    setIsVisible(!isVisible); // Toggle visibility
                })
                .catch(function (res) {
                    console.log(res,)
                });

        },
    });


    const editDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };


    useEffect(() => {
        // Update the select element when formik values change
        const selectElement = document.getElementById('edit_contact');
        if (selectElement) {
            selectElement.value = formik.values.edit_contact;
        }
    }, [edit_formik.values.edit_contact]);



    /**********************  options *******************/
    const options = [
        { value: 'Cat', label: 'Call' },
        { value: 'Dog', label: 'Dog' },
        { value: 'Others', label: 'Others' },
        { value: 'None', label: 'None' },
    ];

    // Custom styles for react-select
const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,  // Ensure the dropdown menu appears above other elements
    }),
};


    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Deals<span className="count-title">{totalCount}</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="filter-section filter-flex">
                                        <div className="sortby-list">
                                            <ul>

                                                <li>
                                                    <div className="form-wrap icon-form">
                                                        <span className="form-icon"><i className="ti ti-calendar"></i></span>
                                                        <input type="text" className="form-control bookingrange" placeholder />
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div className="export-dropdwon">
                                                        <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                        <div className="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Deals</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead classname="thead-light">
                                                <tr>
                                                    <th classname="no-sort">
                                                        <label classname="checkboxs">
                                                            <input type="checkbox" id="select-all" /><span classname="checkmarks"></span></label>
                                                    </th>
                                                    <th classname="no-sort"></th>
                                                    <th>Deal Name</th>
                                                    <th>Stage</th>
                                                    <th>Deal Value</th>
                                                    <th>Tags</th>
                                                    <th>Expected Close Date</th>
                                                    <th>Owner</th>
                                                    <th>Probability </th>
                                                    <th>Status</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        <div className="toggle-popup">
           <div className="sidebar-layout">
            <div className="sidebar-header">
                <h4>Add New Deals</h4>
                <a href="#" className="sidebar-close toggle-btn"><i className="ti ti-x"></i></a>
               </div>
               <div className="toggle-body">
                <div className="pro-create">

        
              <form onSubmit={formik.handleSubmit} >
                  <div className="row">
                  
                  <div className="col-md-11">
                     <div className="mb-3">
                     <label className="col-form-label">Select Existing Leads<span className="text-danger">*</span></label>
                         <Select
                                    id="lead_id"
                                    name="lead_id"
                                    isMulti
                                    value={leadsData
                                        ?.filter((lead) => formik.values.lead_id.includes(lead.pk_lead_id))
                                        .map((lead) => ({
                                        value: lead.pk_lead_id,
                                        label: lead.lead_first_name + ' ' + lead.lead_last_name,
                                        }))
                                    }
                                    onChange={(selectedOptions) => {
                                        formik.setFieldValue(
                                        'lead_id', // Update this to match the name of the form field
                                        selectedOptions ? selectedOptions.map((option) => option.value) : []
                                        );
                                    }}
                                    options={leadsData?.map((lead) => ({
                                        value: lead.pk_lead_id,
                                        label: lead.lead_first_name + ' ' + lead.lead_last_name,
                                    }))}
                                    placeholder="Select Lead"
                                    />


                        </div>
                     </div>  
                     <div className="col-md-1">
                     <label className="col-form-label" style={{marginTop:20}}></label>
                         {/* <a href="javascript:void(0);" class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_add"><i class="ti ti-square-rounded-plus me-2"></i></a> */}
                        <Button variant="primary" onClick={handleShow}>
                        +</Button>

                                        {/* Modal for Adding New Lead */}
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Add New Lead</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Formik
                                                    initialValues={{
                                                        lead_first_name: '',
                                                        lead_last_name: '',
                                                        lead_mobile: '',
                                                        lead_email: '',
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={(values, { resetForm }) => {
                                                        mutation.mutate(values, {
                                                            onSuccess: () => {
                                                                handleClose(); // Close modal on success
                                                                resetForm();   // Reset form fields after successful submission
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {({ values, errors, touched, handleChange }) => (
                                                        <Form>
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                <Field
                                                                    name="lead_first_name"
                                                                    className="form-control"
                                                                    value={values.lead_first_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.lead_first_name && errors.lead_first_name ? (
                                                                    <div className="text-danger">{errors.lead_first_name}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                <Field
                                                                    name="lead_last_name"
                                                                    className="form-control"
                                                                    value={values.lead_last_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.lead_last_name && errors.lead_last_name ? (
                                                                    <div className="text-danger">{errors.lead_last_name}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Mobile</label>
                                                                <Field
                                                                    name="lead_mobile"
                                                                    className="form-control"
                                                                    value={values.lead_mobile}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.lead_mobile && errors.lead_mobile ? (
                                                                    <div className="text-danger">{errors.lead_mobile}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <Field
                                                                    name="lead_email"
                                                                    className="form-control"
                                                                    value={values.lead_email}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.lead_email && errors.lead_email ? (
                                                                    <div className="text-danger">{errors.lead_email}</div>
                                                                ) : null}
                                                            </div>

                                                            <Button variant="primary" type="submit">
                                                                Add Lead
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                        </div>  


                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Deal Name <span className="text-danger">*</span></label>
                           <input className="form-control" name="deal_name" value={formik.values.deal_name} onChange={(e) => setInputValue("deal_name", e.target.value)} />
                            <small className="error">{formik.errors.deal_name}</small>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <div className="d-flex align-items-center justify-content-between">
                              <label className="col-form-label">Pipeine <span className="text-danger">*</span></label>
                              {/* <a href className="label-add " data-bs-toggle="offcanvas" data-bs-target="#offcanvas_pipeline">
                              <i className="ti ti-square-rounded-plus"></i>
                              Add New
                              </a> */}
                           </div>
                           <select className="form-control" name="pipeine" as="select" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pipeine}>
                              <option value="">-Select Choose-</option>
                              <option value="sales">Sales</option>
                              <option value="marketing">Marketing</option>
                              <option value="Calls">Calls</option>
                           </select>
                           <small className="error">{formik.errors.pipeine}</small>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Status <span className="text-danger">*</span></label>
                           <select className="form-control" as="select" name="deals_status" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.deals_status}>
                              <option value="">-Select Choose-</option>
                              <option value="cold lead">Cold Lead</option>
                              <option value="initial">Initial</option>
                              <option value="proposal">Proposal</option>
                              <option value="negotiation">Negotiation</option>
                              <option value="confirmed">Confirmed</option>
                              <option value="lost">Lost</option>
                           </select>
                           <small className="error">{formik.errors.deals_status}</small>
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Deal Value<span className="text-danger"> *</span></label>
                           {/* <input className="form-control" type="text" /> */}
                           <input className="form-control" name="deal_value" value={formik.values.deal_value} onChange={(e) => setInputValue("deal_value", e.target.value)} />
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Currency <span className="text-danger">*</span></label>
                           <select className="form-control" as="select" name="currency" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.currency}>
                                <option value="">-Select Choose-</option>
                                <option value="doller">$</option>
                                <option value="pound ">£</option>
                                <option value="inr">₹</option>
                           </select>
                           <small className="error">{formik.errors.currency}</small>
                        </div>
                     </div>
                     
                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Customer Existing Contacts <span className="text-danger">*</span></label>
                           

                        <Select
                            id="contact"
                            name="contact"
                            options={contacts}
                            isMulti
                            value={contacts.filter(option => formik.values.contact.includes(option.value))}
                            onChange={(selectedOptions) => {
                                formik.setFieldValue(
                                    'contact',
                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                );
                            }}
                            styles={customStyles} />


                        </div>
                        <div className="mb-3">
                           <label className="col-form-label">Project <span className="text-danger">*</span></label>
                          
                            <Select
                                id="product"
                                name="product"
                                options={products}
                                isMulti
                                value={products.filter(option => formik.values.product.includes(option.value))}
                                onChange={(selectedOptions) => {
                                    formik.setFieldValue(
                                        'product',
                                        selectedOptions ? selectedOptions.map(option => option.value) : []
                                    );
                                }}
                                styles={customStyles} />

                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Due Date <span className="text-danger">*</span></label>
                           <div className="icon-form">
                              <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                              <input type="date" className="form-control" name="due_date" value={formik.values.due_date} onChange={(e) => setInputValue("due_date", e.target.value)} />
                              {/* <input type="text" className="form-control datetimepicker" placeholder /> */}
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Expected Closing Date <span className="text-danger">*</span></label>
                           <div className="icon-form">
                              <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                              {/* <input type="text" className="form-control datetimepicker" placeholder /> */}
                              <input type="date" className="form-control" name="expected_closing_date" value={formik.values.expected_closing_date} onChange={(e) => setInputValue("expected_closing_date", e.target.value)} />
                              
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Assignee <span className="text-danger">*</span></label>
                             <Select
                                id="assignee"
                                name="assignee"
                                options={assignees}
                                isMulti
                                value={assignees.filter(option => formik.values.assignee.includes(option.value))}
                                onChange={(selectedOptions) => {
                                    formik.setFieldValue(
                                        'assignee',
                                        selectedOptions ? selectedOptions.map(option => option.value) : []
                                    );
                                }}
                                styles={customStyles} />
                        </div>
                     </div>
                     
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Priority <span className="text-danger">*</span></label>
                          
                                {/* <select
                                    className="select"
                                    id="priority" // Ensure the name matches the formik field
                                    name="priority" // Ensure the name matches the formik field
                                    onChange={formik.handleChange} // Handles the change of the select field
                                    onBlur={formik.handleBlur} // Handles the blur (validation on leaving the field)
                                    value={formik.values.priority1} // Sets the value from formik state
                                    >
                                    <option value="">-Select Priority-</option>
                                    <option value="high">High</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                </select> */}

                                  <select className="form-control" as="select" name="priority" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.priority}>
                                    <option value="">-Select Priority-</option>
                                    <option value="high">High</option>
                                    <option value="low ">Low</option>
                                    <option value="medium">Medium</option>
                                </select>

                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="mb-3"> 
                           <label className="col-form-label">Description <span className="text-danger">*</span></label>
                           {/* <div className="summernote" name="description" value={formik.values.description} onChange={(e) => setInputValue("description", e.target.value)}></div> */}
                           {/* <div className="summernote"></div> */}
                           <input className="form-control" name="description" value={formik.values.description} onChange={(e) => setInputValue("description", e.target.value)} />
                        </div>
                     </div>
                  </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
                        <button type="submit" className="btn btn-primary">Create </button>
                    </div>
               </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Contact</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                <form onSubmit={edit_formik.handleSubmit}>
                    <input type="hidden1" name="edit_deal_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_deal_id1} />

                    <div className="row">

                    <div className="col-md-11">
                     <div className="mb-3">
                     <label className="col-form-label">Select Existing Leads<span className="text-danger">*</span></label>
                        <Select
                        id="edit_lead_id"
                        name="edit_lead_id"
                        isMulti
                        value={leadsData
                            ?.filter((lead) => edit_formik.values.edit_lead_id.includes(lead.pk_lead_id))
                            .map((lead) => ({
                            value: lead.pk_lead_id,
                            label: lead.lead_first_name + ' ' + lead.lead_last_name,
                            }))
                        }
                        onChange={(selectedOptions) => {
                            edit_formik.setFieldValue(
                            'lead_id', // Ensure this matches the formik field name
                            selectedOptions ? selectedOptions.map((option) => option.value) : []
                            );
                        }}
                        options={leadsData?.map((lead) => ({
                            value: lead.pk_lead_id,
                            label: lead.lead_first_name + ' ' + lead.lead_last_name,
                        }))}
                        placeholder="Select Lead"
                        />


                        </div>
                     </div>  
                     <div className="col-md-1">
                     <label className="col-form-label" style={{marginTop:20}}></label>
                         {/* <a href="javascript:void(0);" class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_add"><i class="ti ti-square-rounded-plus me-2"></i></a> */}
                        <Button variant="primary" onClick={handleShow}>
                        +</Button>

                                       
                    </div> 


                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Deal Name <span className="text-danger">*</span></label>
                          
                           <input type="text" className="form-control" name="edit_deal_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_deal_name} />
                            <small className="error">{edit_formik.errors.edit_deal_name}</small>


                          
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <div className="d-flex align-items-center justify-content-between">
                              <label className="col-form-label">Pipeine <span className="text-danger">*</span></label>
                            
                           </div>
                          <select className="form-control" as="select" name="edit_pipeine" onChange={edit_formik.handleChange} >
                                <option value="">-Select Choose-</option>
                                <option value="sales" selected={'sales' === editDeals.pipeine}>Sales</option>
                                <option value="marketing" selected={'marketing' === editDeals.pipeine}>Marketing</option>
                                <option value="calls" selected={'calls' === editDeals.pipeine}>Calls</option>
                            </select>
                            <small className="error">{formik.errors.edit_pipeine}</small>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Status <span className="text-danger">*</span></label>
                           <select className="form-control" as="select" name="edit_deals_status" onChange={edit_formik.handleChange} >
                                <option value="">-Select Choose-</option>
                                <option value="cold lead" selected={'cold lead' === editDeals.deals_status}>Cold Lead</option>
                                <option value="initial" selected={'initial' === editDeals.deals_status}>Initial</option>
                                <option value="proposal" selected={'proposal' === editDeals.deals_status}>Proposal</option>
                                <option value="negotiation" selected={'negotiation' === editDeals.deals_status}>Negotiation</option>
                                <option value="confirmed" selected={'confirmed' === editDeals.deals_status}>Confirmed</option>
                                <option value="lost" selected={'lost' === editDeals.deals_status}>Lost</option>

                            </select>
                            <small className="error">{formik.errors.edit_deals_status}</small>

                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Deal Value<span className="text-danger"> *</span></label>
                           {/* <input className="form-control" type="text" /> */}
                           <input className="form-control" name="edit_deal_value" value={edit_formik.values.edit_deal_value} onChange={(e) => setInputValue("edit_deal_value", e.target.value)} />
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Currency <span className="text-danger">*</span></label>
                           <select className="form-control" as="select" name="edit_currency" onChange={edit_formik.handleChange} >
                                <option value="">-Select Choose-</option>
                                <option value="doller" selected={'doller' === editDeals.currency}>$</option>
                                <option value="pound " selected={'pound ' === editDeals.currency}>£</option>
                                <option value="inr" selected={'inr' === editDeals.currency}>₹</option>
                            </select>
                            <small className="error">{formik.errors.edit_currency}</small>

                        </div>
                     </div>
                     
                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Contact <span className="text-danger">*</span></label>
                           


                                <Select
                                id="edit_contact"
                                name="edit_contact"
                                options={contacts} // Your contacts array [{ value: 1, label: 'Contact 1' }, { value: 2, label: 'Contact 2' }, ...]
                                isMulti
                                // Set initial values from Edit_formik
                                value={contacts.filter(option => edit_formik.values.edit_contact.includes(option.value))}
                             // Handle change event to update Formik's state
                                onChange={(selectedOptions) => {
                                    edit_formik.setFieldValue(
                                    'edit_contact',
                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                    );
                                }}

                                // Optional: custom styles for the Select component
                                styles={customStyles}
                                />


                        </div>
                        <div className="mb-3">
                           <label className="col-form-label">Project <span className="text-danger">*</span></label>
                            <Select
                                id="edit_product_id"
                                name="edit_product_id"
                                options={contacts} // Your contacts array [{ value: 1, label: 'Contact 1' }, { value: 2, label: 'Contact 2' }, ...]
                                isMulti
                                // Set initial values from Edit_formik
                                value={contacts.filter(option => edit_formik.values.edit_product_id.includes(option.value))}
                               // Handle change event to update Formik's state
                                onChange={(selectedOptions) => {
                                    edit_formik.setFieldValue(
                                    'edit_product_id',
                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                    );
                                }}

                                // Optional: custom styles for the Select component
                                styles={customStyles}
                                />

                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Due Date <span className="text-danger">*</span></label>
                           <div className="icon-form">
                              <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                             <input type="date" className="form-control" name="edit_due_date" value={edit_formik.values.edit_due_date} onChange={(e) => setInputValue("edit_due_date", e.target.value)} />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Expected Closing Date <span className="text-danger">*</span></label>
                           <div className="icon-form">
                              <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                              <input type="date" className="form-control" name="edit_expected_closing_date" value={edit_formik.values.edit_expected_closing_date} onChange={(e) => setInputValue("edit_expected_closing_date", e.target.value)} />
                              
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="mb-3">
                           <label className="col-form-label">Assignee <span className="text-danger">*</span></label>
                           
                            <Select
                                id="edit_assignee"
                                name="edit_assignee"
                                options={contacts} // Your contacts array [{ value: 1, label: 'Contact 1' }, { value: 2, label: 'Contact 2' }, ...]
                                isMulti
                                // Set initial values from Edit_formik
                                value={contacts.filter(option => edit_formik.values.edit_assignee.includes(option.value))}
                               // Handle change event to update Formik's state
                                onChange={(selectedOptions) => {
                                    edit_formik.setFieldValue(
                                    'edit_assignee',
                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                    );
                                }}

                                // Optional: custom styles for the Select component
                                styles={customStyles}
                                />
                        </div>
                     </div>
                     
                     <div className="col-md-6">
                        <div className="mb-3">
                           <label className="col-form-label">Priority <span className="text-danger">*</span></label>
                           <select className="form-control" as="select" name="edit_priority" value={edit_formik.values.edit_priority} onChange={edit_formik.handleChange} >
                                <option value="">-Select Choose-</option>
                                <option value="highy" selected={'highy' === editDeals.priority}>Highy</option>
                                <option value="low " selected={'low ' === editDeals.priority}>Low</option>
                                <option value="medium" selected={'medium' === editDeals.priority}>Medium</option>
                            </select>
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="mb-3"> 
                           <label className="col-form-label">Description <span className="text-danger">*</span></label>
                          
                           <input className="form-control" name="edit_description" value={edit_formik.values.edit_description} onChange={(e) => setInputValue("edit_description", e.target.value)} />
                        </div>
                       </div>
                     </div>
                            <div className="submit-button text-end">
                                <a href="#" className="btn btn-light sidebar-close1">Cancel</a>
                                <button type="submit" className="btn btn-primary">
                                    Update
                                </button>

                            {/* <a href="#" className="btn btn-primary">Save Changes</a> */}
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            
            


            <div className="modal custom-modal fade" id="create_contact" role="dialog" style={{ opacity: 1 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 m-0 justify-content-end">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-light-blue">
                                    <i className="ti ti-user-plus"></i>
                                </div>
                                <h3>Contact Created Successfully!!!</h3>
                                <p>View the details of contact, created</p>
                                <div className="col-lg-12 text-center modal-btn">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    {/* <a href="/contacts" re className="btn btn-primary">View Details</a> */}
                                    <button onClick={handleContactsNavigation} className="btn btn-primary">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal custom-modal fade" id="save_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New View</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap">
                                    <label className="col-form-label">View Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-danger">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" id="access_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Access For</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap icon-form">
                                    <span className="form-icon"><i className="ti ti-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>
                                <div className="access-wrap">
                                    <ul>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-19.jpg`} alt />
                                                    <a href="#">Darlee Robertson</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-20.jpg`} alt />
                                                    <a href="#">Sharon Roy</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-21.jpg`} alt />
                                                    <a href="#">Vaughan</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-01.jpg`} alt />
                                                    <a href="#">Jessica</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-16.jpg`} alt />
                                                    <a href="#">Carol Thomas</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs" >
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-22.jpg`} alt />
                                                    <a href="#">Dawn Mercha</a>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Deals;