import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import $ from 'jquery';
import { Modal, Button } from 'react-bootstrap';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import * as Yup from 'yup';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4';

import Header from './Header'


const permissionId = 1; // Dynamic value to pass

 const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Fetch existing leads
const fetchCompanyData = async () => {
    const response = await axios.get(`${apiUrl}/role-list`);
    console.log("Company list", response.data);
    return response;
};


// Fetch existing leads
const fetchRoleWiseModule = async (permissionEditData) => {
   const res = axios.get(`${apiUrl}/rolewise-module`, permissionEditData); 
    console.log("rolewise module", res.data);
    return res;
};



const fetchRoleWisePermissionData = async (role_id) => {
      alert("=====>>"+role_id);
     const response = axios.post(`${apiUrl}/role-wise-permission-data/${role_id}`, role_id);
     console.log("Role Wise Permission Data", response.data);
     return response;
 };



const RolesPermissions = () => {

    const [inputValue, setInputValue] = useState(''); // Define state for input

    // Add new lead API call
    const addCompany = async (newCompany) => {
        const response = await axios.post(`${apiUrl}/add-role`, newCompany);
        return response.data;
    };


    // Function to update a company
    const updateCompany = (values) => {

        const res = axios.put(`${apiUrl}/update-role/${values.edit_role_id}`, values); // Update company endpoint
        //refetch()
        return res;
    }

    const RoleWisePermissionMutation = (values) => {

        const res = axios.put(`${apiUrl}/update-rolewise-permission/${values.role_id}`, values); // Update Role Wise Permission endpoint
        //refetch()
        return res;
    }

    


    const queryClient = useQueryClient(); // For cache management

    // Fetch existing leads using useQuery
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['roleList'],
        queryFn: fetchCompanyData,
    });




    // Mutation to update the lead
    const updateMutation = useMutation({
        mutationFn: updateCompany,
        onSuccess: () => {
            queryClient.invalidateQueries(['roleList']);
            
        },
    });

    // Mutation to update the lead
    const updateRoleWisePermissionMutation = useMutation({
        mutationFn: RoleWisePermissionMutation,
        onSuccess: () => {
            queryClient.invalidateQueries(['roleList']);
            
        },
    });


    // Mutation for adding a new lead
    const mutation = useMutation({
        mutationFn: addCompany,
        onSuccess: (newCompany) => {
            // Update the leads list without refreshing by invalidating the query or updating cache manually
            queryClient.setQueryData(['newRoleList'], (oldData) => ({
                ...oldData,
                data: [...oldData.data, newCompany], // Append new lead to the existing leads
            }));
        },
    });



    // State for modal and form visibility
    const [show, setShow] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedPermission, setSelectedPermission] = useState(null);

    const schema = Yup.object().shape({
        role_name: Yup.string().min(3).required('Role name is required'),
        checkboxes: Yup.array().min(1, 'You must select at least one option'), // Validate at least one checkbox
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            role_name: '',
            checkboxes: [], // Store the checked values in an array
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            mutation.mutate(values); // Pass form values to the mutation function
            alert('Successfully added data');

            $('.toggle-popup1').removeClass('sidebar-popup');
            $('.sidebar-popoverlay').removeClass('open');

        },
    });


    //#######################  Update from ####################################//

  useEffect(() => {
       
        if (selectedRole) {
            console.log("Hi===============", selectedRole.role_name)

            role_edit_formik.setValues({
                edit_role_id: selectedRole.role_id || '',
                edit_role_name: selectedRole.role_name || '',


            });
        }
    }, [selectedRole]); // Add edit_formik to dependency array


const edit_schema = Yup.object().shape({
        edit_role_name: Yup.string().min(3).required('Role name is required'),
    });


    const role_edit_formik = useFormik({
        initialValues: {
            edit_role_id    : '',
            edit_role_name  : '',
        },
        validationSchema: edit_schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("=========Edit Role Id====", values)
            // updateCompany(values)
            $('.edit_role_popup').hide();
            $('.modal-backdrop').css('display','none');
            updateMutation.mutate({ ...selectedRole, ...values }); // Call the mutation to update the lead
            //$("#edit_role").modal('hide');
            alert('Successfully updated data');
            
         },
    });




    useEffect(() => {
        
        if (selectedPermission) {
            console.log("Hiiiiii===============", selectedPermission)
            edit_formik.setValues({
                edit_role_id: selectedPermission.pk_role_id || '',
                edit_role_name: selectedPermission.role_name || '',
                submodules: selectedPermission.moduleName || [],
                
            });
        }
    }, [selectedPermission]); 
    

    const edit_formik = useFormik({
        initialValues: {
            edit_role_id      : '',
            edit_role_name    : '',
            edit_module_name  : '',
            submodules        : [],
        },
        validationSchema: edit_schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("=========Edit Role Id====", values.edit_role_id)
            // updateCompany(values)
            $('.edit_role_popup').show();
            $('.modal-backdrop').css('display','block');

            updateRoleWisePermissionMutation.mutate({ ...selectedRole, ...values }); // Call the mutation to update the lead
            $("#edit_permission").modal('hide');
            alert('Successfully updated data111');
            
         },
    });


    


    // Function to open the modal and set the selected lead data
    const handleOpenModal = (roleEditData) => {
        
        console.log("============ com edit data===", roleEditData)
        setSelectedRole(roleEditData);
        $('.modal-backdrop').css('display','block');
    };



    
    // Function to open the modal and set the selected lead data
    const permissionOpenModal = (permissionEditData) => {
        console.log("============1010101 Permission===", permissionEditData)
        alert(permissionEditData.role_id)
        
        setSelectedPermission(permissionEditData);
        
        fetchRoleWisePermissionData(permissionEditData.role_id);

        $('.modal-backdrop').css('display','block');

       // fetchRoleWiseModule(permissionEditData);



    };


    // Initialize DataTable after the component mounts and the data is fetched
    useEffect(() => {
        if (data) {
            // Initialize DataTable after data has been populated in the table
            $('#categorieslist_data').DataTable({
                destroy: true,  // Destroy any existing DataTable instances before reinitializing
                paging: true,   // Enable pagination
                searching: true, // Enable search functionality
                ordering: true,  // Enable column ordering
            });
        }
    }, [data]);  // Re-run effect when data changes



    // Function to update a company
    const updateCompanyStatus = (values) => {
        console.log("===========update company state===", values);
        const res = axios.put(`${apiUrl}/update-role-status/${values.roleId}`, values); // Update company endpoint
        //refetch()
        return res;
    }


    const statusMutation = useMutation({
        mutationFn: updateCompanyStatus,
        onSuccess: () => {
            queryClient.invalidateQueries(['roleList']);
        },
    });



    // Handle status change (active/inactive)
    const handleStatusChange = (roleId, currentStatus) => {
        //console.log(roleId, '=====', currentStatus)
        const userConfirmed = window.confirm("Are you sure you want to proceed?");

        if (userConfirmed) {
            try {
                const newStatus = currentStatus === '0' ? 'inactive' : 'active'; // Toggle status
                statusMutation.mutate({ roleId, status: newStatus });
            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


    };




    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Roles Permissions<span className="count-title">-</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="filter-section filter-flex">
                                        <div className="sortby-list"></div>

                                        <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_role"><i class="ti ti-square-rounded-plus me-2"></i>Add New Roles</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Role Name</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                    <th> Add Permission</th>
                                                    <th> Edit Permission</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data?.data.data?.map(item => (
                                                    
                                                    <tr key={item.pk_role_id}>
                                                        <td>{item.role_name}</td>
                                                        <td>
                                                            <button className={`btn btn-pill ${item.is_active === '0' ? 'btn-danger' : 'btn-success'}`}
                                                                onClick={() => handleStatusChange(item.pk_role_id, item.is_active)}
                                                            >
                                                                {item.is_active === '0' ? 'InActive' : 'Active'}
                                                            </button>
                                                        </td>
                                                        <td>   
                                                            <a class="btn btn-pill" href="javascript:void(0);" data-bs-toggle="modal" onClick={() => handleOpenModal(item)} data-bs-target="#edit_role"><i class="ti ti-edit text-blue"></i> Edit</a>
                                                        </td>
                                                        <td>  
                                                      <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" onClick={() => permissionOpenModal(item)} data-bs-target="#edit_permission"><i class="ti ti-shield text-success"></i> Permission</a>
                                                      </td>
                                                        <td>  
                                                     
                                                       <Link className="dropdown-item" to={`/permissions?permissionId=${item.role_id}`}>
                                                        <i className="ti ti-shield text-success"></i> Permission
                                                        </Link>


                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="modal fade" id="add_role" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Role</h5>
                            <button class="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <i class="ti ti-x"></i>
                            </button>
                        </div>
                        <form onSubmit={formik.handleSubmit} >
                            <div class="modal-body">
                                <div class="mb-0">
                                    <label class="col-form-label">Role Name <span class="text-danger">*</span></label>
                                    <input className="form-control" name="role_name"
                                        value={formik.values.role_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <small className="error">{formik.errors.role_name}</small>
                                </div>
                                
                             </div>
                            <div class="modal-footer">
                                <div class="d-flex align-items-center justify-content-end m-0">
                                    <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" class="btn btn-primary">Create</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



                    <div class="modal fade edit_role_popup" id="edit_role" role="dialog">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Edit Role</h5>
                                    <button class="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="ti ti-x"></i>
                                    </button>
                                </div>
                                
                                <form onSubmit={role_edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_role_id" onChange={role_edit_formik.handleChange} onBlur={role_edit_formik.handleBlur} value={role_edit_formik.values.edit_role_id} />

                                    <div className="modal-body">
                                        <div class="mb-0">
                                            <label class="col-form-label">Role Name <span class="text-danger">*</span></label>
                                            <input className="form-control" name="edit_role_name"
                                                value={role_edit_formik.values.edit_role_name}
                                                onChange={role_edit_formik.handleChange}
                                                onBlur={role_edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_role_name}</small>
                                        </div>
                                    </div>
                                    <div classNane="modal-footer">
                                        <div classNane="d-flex align-items-center justify-content-end m-0">
                                            <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
                                            <button type="submit" classNane="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                </form>
                             </div>
                        </div>
                    </div>


                    <div class="modal fade edit_permission" id="edit_permission" role="dialog">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Role Wise Permission</h5>
                                    <button class="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="ti ti-x"></i>
                                    </button>
                                </div>
                                
                                <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden" name="edit_company_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_role_id} />

                            <div className="modal-body">
                                <div className="mb-0">
                                <label className="col-form-label">
                                    Role Name <span className="text-danger">*</span>
                                </label>
                                <input className="form-control" name="edit_role_name" value={edit_formik.values.edit_role_name} onChange={edit_formik.handleChange}
                                    onBlur={edit_formik.handleBlur} />
                                <small className="error">{edit_formik.errors.edit_role_name}</small>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                <label>Module Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="edit_module_name"
                                    value={edit_formik.values.edit_module_name}
                                    onChange={edit_formik.handleChange}
                                />
                                </div>

                      <div className="col-md-12">
                            {edit_formik.values.submodules.map((submodule, index) => (
                                
                                <div key={index} className="mt-3">
                                <label>Submodule Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={`submodules[${index}].name`}
                                    value={edit_formik.values.submodules[index].name}
                                    onChange={edit_formik.handleChange}
                                />
                               
                               
                                <div className="d-flex flex-wrap mt-3">
                                    <div className="me-2">
                                    <input
                                        type="checkbox"
                                        name={`submodules[${index}].add`}
                                        checked={edit_formik.values.submodules[index].add || false}
                                        onChange={edit_formik.handleChange}
                                    />
                                    <label className="ms-1">Add</label>
                                    </div>

                                    <div className="me-2">
                                    <input
                                        type="checkbox"
                                        name={`submodules[${index}].edit`}
                                        checked={edit_formik.values.submodules[index].edit || false} 
                                        onChange={edit_formik.handleChange}
                                    />
                                    <label className="ms-1">Edit</label>
                                    </div>
                                    
                                    <div className="me-2">
                                    <input
                                        type="checkbox"
                                        name={`submodules[${index}].delete`}
                                        checked={edit_formik.values.submodules[index].delete || false}
                                        onChange={edit_formik.handleChange}
                                    />
                                    <label className="ms-1">Delete</label>
                                    </div>

                                    <div className="me-2">
                                    <input
                                        type="checkbox"
                                        name={`submodules[${index}].view`}
                                        checked={edit_formik.values.submodules[index].view || false} 
                                        onChange={edit_formik.handleChange}
                                    />
                                    <label className="ms-1">View</label>
                                    </div>

                                </div>
                                </div>
                            ))}

                            <button
                            type="button" className="btn btn-info" style={{marginTop:10}}
                            onClick={() =>
                            edit_formik.setFieldValue("submodules", [
                                 ...edit_formik.values.submodules,
                            { name: "", add: false, delete: false, view: false, edit: false }, // Added view
                            ])
                            }
                            >
                            Add Submodule
                            </button>
                            <br></br><br></br><br></br>
                            </div> 
                            </div>



                            <div className="modal-footer">
                                <div className="d-flex align-items-center justify-content-end m-0">
                                <a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                <button type="submit" className="btn btn-primary">
                                    Update Permission
                                </button>
                                </div>
                            </div>
                            </form>


                             </div>
                        </div>
                    </div>
                   






            {/* <div className="toggle-popup">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Add New Company</h4>
                        <a href="#" className="sidebar-close toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={formik.handleSubmit} >
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Company Name<span className="text-danger">*</span></label>
                                            <input className="form-control" name="company_name"
                                                value={formik.values.company_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.company_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Email  <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.email}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Phone<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="phone"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.phone}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Website <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="website"
                                                value={formik.values.website}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.website}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Industry  <span className="text-danger"> *</span></label>
                                            <select class="form-control" name="industry" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.industry}>
                                                <option>Choose</option>
                                                <option value="Retail Industry">Retail Industry</option>
                                                <option value="Banking">Banking</option>
                                                <option value="Hotels">Hotels</option>
                                                <option value="Financial Services">Financial Services</option>
                                                <option value="Insurance">Insurance</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Language<span className="text-danger"> *</span></label>
                                            <select class="form-control" name="language" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.language}>
                                                <option>Choose</option>
                                                <option value="English">English</option>
                                                <option value="Arabic">Arabic</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Hindi">Hindi</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Address <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Country <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="country"
                                                as="select"
                                                value={formik.values.country}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedCountry(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Country</option>
                                                {countries && countries.map((country, cases) => (
                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">State <span className="text-danger">*</span></label>

                                            <select
                                                className="form-control"
                                                name="state"
                                                value={formik.values.state}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedState(e.target.value);
                                                }}

                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                            <select disabled={!selectedState} className="form-control" name="city" as="select"
                                                value={formik.values.city}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedCity(e.target.value);
                                                }}>
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Zipcode <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="zipcode"
                                                value={formik.values.zipcode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Facebook <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="facebook"
                                                value={formik.values.facebook}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Linkedin <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="linkedin"
                                                value={formik.values.linkedin}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Twitter <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="twitter"
                                                value={formik.values.twitter}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Whatsapp <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="whatsapp"
                                                value={formik.values.whatsapp}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Instagram <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="instagram"
                                                value={formik.values.instagram}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Create </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Contact</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_company_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_company_id} />

                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Company Name<span className="text-danger">*</span></label>
                                            <input className="form-control" name="edit_company_name"
                                                value={edit_formik.values.edit_company_name}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_company_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Email  <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="edit_email"
                                                value={edit_formik.values.edit_email}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_email}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Phone<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_phone"
                                                value={edit_formik.values.edit_phone}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_phone}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Website <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_website"
                                                value={edit_formik.values.edit_website}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_website}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Industry  <span className="text-danger"> *</span></label>
                                            <select class="form-control" name="edit_industry" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_industry}>
                                                <option value="">Choose</option>
                                                <option value="Retail Industry" >Retail Industry</option>
                                                <option value="Banking">Banking</option>
                                                <option value="Hotels">Hotels</option>
                                                <option value="Financial Services">Financial Services</option>
                                                <option value="Insurance">Insurance</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Language<span className="text-danger"> *</span></label>
                                            <select class="form-control" name="edit_language" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_language}>
                                                <option>Choose</option>
                                                <option value="English">English</option>
                                                <option value="Arabic">Arabic</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Hindi">Hindi</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Address <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_address"
                                                value={edit_formik.values.edit_address}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Country <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="edit_country"
                                                as="select"
                                                value={edit_formik.values.edit_country}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedCountry(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Country</option>
                                                {countries && countries.map((country, cases) => (
                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">State <span className="text-danger">*</span></label>

                                            <select
                                                className="form-control"
                                                name="edit_state"
                                                value={edit_formik.values.edit_state}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedState(e.target.value);
                                                }}

                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                            <select disabled={!selectedState} className="form-control" name="edit_city" as="select"
                                                value={edit_formik.values.edit_city}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedCity(e.target.value);
                                                }}>
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Zipcode <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_zipcode"
                                                value={edit_formik.values.edit_zipcode}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Facebook <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_facebook"
                                                value={edit_formik.values.edit_facebook}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Linkedin <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_linkedin"
                                                value={edit_formik.values.edit_linkedin}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Twitter <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_twitter"
                                                value={edit_formik.values.edit_twitter}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Whatsapp <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_whatsapp"
                                                value={edit_formik.values.edit_whatsapp}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Instagram <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_instagram"
                                                value={edit_formik.values.edit_instagram}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>

                                    <button type="submit" className="btn btn-primary"> {mutation.isLoading ? 'Updating...' : 'Update'} </button>
                                </div>
                               
                                {mutation.isError && <div>Error updating form</div>}
                                {mutation.isSuccess && <div>Form updated successfully</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}


        </div>

    )
}

export default RolesPermissions;