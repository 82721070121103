// src/Dashboard.js

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Header from './Header';
import './MyChart.css'; // Import your CSS file

// Initialize FusionCharts once, outside the component
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


const Dashboard = () => {

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
   //alert(apiUrl);
  console.log('Dashboard API URL:', apiUrl);


  // Function to fetch recent deals data
const fetchDeals = async () => {
  try {
    const response = await axios.get(`${apiUrl}/recent-created-deals`); 
    console.log("Lead list for dropdown", response.data.data);
    return response.data.data; 
  } catch (error) {
    console.error("Error fetching recent deals:", error);
    throw error;
  }
};

// Function to fetch deals by stage data
const fetchDealsByStage = async () => {
  try {
    const response = await axios.get(`${apiUrl}/deals-by-stage`); 
    const data = response.data.data || response.data;
    console.log("======Jai======", data);
    return data; 
  } catch (error) {
    console.error("Error fetching deals by stage:", error);
    throw error;
  }
};


// Function to fetch lead trends over time data
const fetchLeadTrendsOverTime = async () => {
   try {
     const response = await axios.get(`${apiUrl}/lead-trends-over-time`); 
     const data = response.data.data || response.data;
     console.log("======lead-trends-over-time======", data);
     return data; 
   } catch (error) {
     console.error("Error fetching lead-trends-over-time", error);
     throw error;
   }
 };


// Function to fetch won deals stage data
const fetchWonDealsStage = async () => {
   try {
     const response = await axios.get(`${apiUrl}/won-deals-stage`); 
     const data = response.data.data || response.data;
     console.log("======won-deals-stage======", data);
     return data; 
   } catch (error) {
     console.error("Error fetching won-deals-stage", error);
     throw error;
   }
 };

// Function to fetch won deals stage data
const fetchDealsByYear = async (id) => {
 // alert(id); // Optional: alert to check the parameter value
  try {
    const response = await axios.get(`${apiUrl}/deals-by-year`, {
      params: { id } // Pass id as a query parameter
    });
    const data = response.data.data || response.data;
    console.log("======deals by current year======", data);
    return data;
  } catch (error) {
    console.error("Error fetching deals by current year", error);
    throw error;
  }
};

 const handleSelectRange = async (range) => {
  fetchDealsByYear(range); // Update selected range in the state
  
};

 
  const { data:data, isLoading:isLoading, error:error, refetch:refetch } = useQuery({
    queryKey: ['dealsList'],
    queryFn: fetchDeals,
  });

  const { data: data2, isLoading: isLoading2, error: error2, refetch: refetch2 } = useQuery({
    queryKey: ['dealsbystage'],
    queryFn: fetchDealsByStage,
  });

  const { data: data3, isLoading: isLoading3, error: error3, refetch: refetch3 } = useQuery({
   queryKey: ['leadTrendsOverTime'],
   queryFn: fetchLeadTrendsOverTime,
 });


 
  const { data: data4, isLoading: isLoading4, error: error4, refetch: refetch4 } = useQuery({
   queryKey: ['wonDealsStage'],
   queryFn: fetchWonDealsStage,
 });


 const { data: data5, isLoading: isLoading5, error: error5, refetch: refetch5 } = useQuery({
   queryKey: ['dealsByYear'],
   queryFn: fetchDealsByYear,
 });



  // Handle loading states for both queries
  
  if (isLoading || isLoading2 || isLoading3  || isLoading4   || isLoading5) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Handle error states for both queries
  if (error || error2  || error3  || error4  || error5) {
    return (
      <div className="alert alert-danger" role="alert">
        Error fetching data: {error?.message || error2?.message  || error3?.message || error4?.message || error5?.message}
      </div>
    );
  }
  

  // Transform data for the Deals By Stage chart
  const chartData = data2
    ? data2
        .filter(item => item.label && item.value)
        .map((item) => ({
          label: item.label,
          value: item.value,
        }))
    : [];

  //console.log("Transformed chartData:", chartData);

  // Chart Configuration for Deals By Stage
  const chartConfigs = {
    type: "column2d", // The chart type (Column chart in 2D)
    width: "100%", // Responsive width
    height: "350", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        xAxisName: "Deal Stages", // X-axis name
        yAxisName: "Deals", // Y-axis name
        numberSuffix: "", // Suffix for the numbers
        theme: "fusion", // Theme for the chart
        showValues: "1", // Show values above the bars
        valueFontSize: "12", // Font size for values
        valueFontColor: "#000000", // Font color for the values
        labelPadding: "10", // Padding below the x-axis labels
        paletteColors: "#52a5a2", // Custom color for data points (bars)
      },
      // Chart Data
      data: chartData,
    },
  };

  // Sample Line Chart Data (Leads Trends Over Time)
//   const lineChartData = [
//     { label: "January", value: "150" },
//     { label: "February", value: "200" },
//     { label: "March", value: "180" },
//     { label: "April", value: "220" },
//     { label: "May", value: "260" },
//     { label: "June", value: "300" },
//   ];


const lineChartData = data3
? data3
    .filter(item => item.label && item.value)
    .map((item) => ({
      label: item.label,
      value: item.value,
    }))
: [];


  // Chart Configuration for Line Chart
  const lineChartConfigs = {
    type: "line", // The chart type (Line chart)
    width: "100%", // Responsive width
    height: "350", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Lead Trends Over Time", // Chart caption
        subCaption: "Monthly Leads", // Chart subcaption
        xAxisName: "Month", // X-axis name
        yAxisName: "Number of Leads", // Y-axis name
        numberSuffix: "", // Suffix for the numbers
        theme: "fusion", // Theme for the chart
        showValues: "1", // Show values on the line
        valueFontSize: "12", // Font size for values
        valueFontColor: "#000000", // Font color for the values
      },
      data: lineChartData, // Data for the line chart
    },
  };

  // Sample Bar2D Chart Data (Won Deals Stage)
//   const chartDataWonDealsStage = [
//     { label: "In Pipeline", value: "400" },
//     { label: "Follow Up", value: "250" },
//     { label: "Schedule", value: "180" },
//     { label: "Conversation", value: "470" },
//     { label: "Won", value: "470" }, 
//   ];

const chartDataWonDealsStage = data4
? data4
    .filter(item => item.label && item.value)
    .map((item) => ({
      label: item.label,
      value: item.value,
    }))
: [];




  // Chart Configuration for Won Deals Stage
  const chartConfigsWonDealsStage = {
    type: "bar2d", // The chart type (Bar chart in 2D)
    width: "100%", // Responsive width
    height: "350", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Won Deals Stage", // Chart caption
        subCaption: "Overview of Stages Leading to Won Deals", // Chart subcaption
        xAxisName: "Deal Stages", // X-axis name
        yAxisName: "Number of Deals", // Y-axis name
        numberSuffix: "", // Suffix for the numbers
        theme: "fusion", // Theme for the chart
        showValues: "1", // Show values above the bars
        valueFontSize: "12", // Font size for values
        valueFontColor: "#000000", // Font color for the values
        labelPadding: "10", // Padding below the x-axis labels
        paletteColors: "#52a5a2", // Custom color for data points (bars)
      },
      data: chartDataWonDealsStage,
    },
  };

  // Sample Doughnut2D Chart Data (Deals by Year)
//   const chartDataDealsbyYear = [
//     { label: "Category A", value: "300" },
//     { label: "Category B", value: "150" },
//     { label: "Category C", value: "120" },
//     { label: "Category D", value: "90" },
//     { label: "Category E", value: "60" },
//   ];

const chartDataDealsbyYear = data5
? data5
    .filter(item => item.label && item.value)
    .map((item) => ({
      label: item.label,
      value: item.value,
    }))
: [];

  // Chart Configuration for Deals by Year
  const chartConfigsDealsbyYear = {
    type: "doughnut2d", // The chart type (Doughnut chart)
    width: "100%", // Responsive width
    height: "400", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Deals by Year (Current)", // Chart caption
        subCaption: "Current Year", // Chart subcaption
        numberSuffix: " units", // Suffix for numbers
        theme: "fusion", // Theme for the chart
        doughnutRadius: "45%", // Radius of the doughnut
      },
      data: chartDataDealsbyYear, // Data for the chart
    },
  };

  return (
    <div className="main-wrapper">
      {/* Header Start */}
      <Header />
      {/* Header End */}

      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h3 className="page-title">Deals Dashboard</h3>
                  </div>
                  <div className="col-md-8 float-end ms-auto">
                    <div className="d-flex title-head">
                      <div className="daterange-picker d-flex align-items-center justify-content-center">
                        <div className="form-sort me-2">
                          <i className="ti ti-calendar"></i>
                          <input
                            type="text"
                            className="form-control date-range bookingrange"
                          />
                        </div>
                        <div className="head-icons mb-0">
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Refresh"
                          >
                            <i className="ti ti-refresh-dot"></i>
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Collapse"
                            id="collapse-header"
                          >
                            <i className="ti ti-chevrons-up"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Deals List and Deals By Stage */}
          <div className="row">
            {/* Recently Created Deals Table */}
            <div className="col-md-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1"></i>Recently
                      Created Deals
                    </h4>
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="#"
                      >
                        <i className="ti ti-calendar-check me-2"></i>Last 30 days
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a href="#" className="dropdown-item">
                          Last 15 days
                        </a>
                        <a href="#" className="dropdown-item">
                          Last 30 days
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive custom-table">
                    <table className="table dataTable" id="deals-project">
                      <thead className="thead-light">
                        <tr>
                          <th>Deal Name</th>
                          <th>Pipeline</th>
                          <th>Deal Value</th>
                          <th>Priority</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length > 0 ? (
                          data.map((deal) => (
                            <tr key={deal.deal_id}>
                              <td>{deal.deal_name}</td>
                              <td>{deal.pipeline}</td>
                              <td>{deal.deal_value}</td>
                              <td>{deal.priority}</td>
                              <td>
                                <span className="badge badge-pill bg-success">
                                  {deal.deals_status}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No deals found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Deals By Stage Chart */}
            <div className="col-md-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1"></i>Deals By
                      Stage
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown me-2">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Sales Pipeline
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Marketing Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Sales Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Email
                          </a>
                          <a href="#" className="dropdown-item">
                            Chats
                          </a>
                          <a href="#" className="dropdown-item">
                            Operational
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Last 30 Days
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Last 30 Days
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 15 Days
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 7 Days
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Deals By Stage Chart */}
                  <ReactFC {...chartConfigs} />
                </div>
              </div>
            </div>
          </div>

          {/* Additional Charts: Leads By Stage, Won Deals Stage, Deals by Year */}
          <div className="row">
            {/* Leads By Stage Line Chart */}
            <div className="col-md-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1"></i>Leads By
                      Stage
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown me-2">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Marketing Pipeline
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Marketing Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Sales Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Email
                          </a>
                          <a href="#" className="dropdown-item">
                            Chats
                          </a>
                          <a href="#" className="dropdown-item">
                            Operational
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                          onClick={() => handleSelectRange("Last 3 months")}
                        >
                          Last 3 months
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="javascript:void(0)" className="dropdown-item" onClick={() => handleSelectRange("Last 3 months")}>
                            Last 3 months
                          </a>
                          <a href="javascript:void(0)" className="dropdown-item" onClick={() => handleSelectRange("Last 6 months")}>
                            Last 6 months
                          </a>
                          <a href="javascript:void(0)" className="dropdown-item" onClick={() => handleSelectRange("Last 12 months")}>
                            Last 12 months
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Leads By Stage Line Chart */}
                  <ReactFC {...lineChartConfigs} />
                </div>
              </div>
            </div>

            {/* Won Deals Stage Bar2D Chart */}
            <div className="col-md-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1"></i>Won Deals
                      Stage
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown me-2">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Marketing Pipeline
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Marketing Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Sales Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Email
                          </a>
                          <a href="#" className="dropdown-item">
                            Chats
                          </a>
                          <a href="#" className="dropdown-item">
                            Operational
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Last 3 months
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Last 3 months
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 6 months
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 12 months
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Won Deals Stage Bar2D Chart */}
                  <ReactFC {...chartConfigsWonDealsStage} />
                </div>
              </div>
            </div>
          </div>

          {/* Deals by Year Doughnut2D Chart */}
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="card w-100">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1"></i>Deals by
                      Year
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown me-2">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                        >
                          Sales Pipeline
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Marketing Pipeline
                          </a>
                          <a href="#" className="dropdown-item">
                            Sales Pipeline
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="javascript:void(0)"
                        >
                          Last 3 months
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a href="#" className="dropdown-item">
                            Last 3 months
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 6 months
                          </a>
                          <a href="#" className="dropdown-item">
                            Last 12 months
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Deals by Year Doughnut2D Chart */}
                  <ReactFC {...chartConfigsDealsbyYear} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

