import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useHref, useNavigate, Navigate } from 'react-router-dom';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import Header from './Header'
import { contactsValidation } from './contactsValidation';

import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';
import 'datatables.net-bs4';
import './styleError.css'; // Import your CSS file



import { differenceInYears } from 'date-fns';


function EmployeeConatacts() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // a local state to store the currently selected file.
    const [inputValue] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [date, setDate] = useState(new Date());
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    
    const [reportingManager, setReportingManager] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [contactList, setContactList] = useState([]);
    const [data, setData] = useState([]);
    const [editContact, setEditContact] = useState([]);
    const [statusContact, setStatusContact] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [gender, setGender] = useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFileAdd, setSelectedFileAdd] = React.useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [image2, setImage2] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);

    const [hiddenEmpIdValue, setHiddenEmpIdValue] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

   const navigate = useNavigate();

    const schema = Yup.object().shape({
        category_type: Yup.string().required('Please select category type'),
        source_of_contact: Yup.string().required('Please select source of contact'),
        first_name: Yup.string().min(3).required('First Name is required'),
        last_name: Yup.string().min(3).required('Last Name is required'),
        client_gender: Yup.string().required('Please select gender'),
        client_dob: Yup.date()
            .nullable()
            .required("Date of birth is required"),
        client_phone: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            .required('Mobile number is required'),
        client_email: Yup.string().email('Invalid email address').required('Email is required'),
        client_address: Yup.string().min(3).required('Address is required'),
        client_country: Yup.string().required('Please select country'),
        client_state: Yup.string().required('Please select state'),
        //client_city: Yup.string().required('Please select city'),

        client_job_title: Yup.string().min(3).required('Job title is required'),
        department: Yup.string().min(3).required('Department is required'),
        location: Yup.string().min(3).required('Location is required'),

        client_designation: Yup.string().min(2).required('Designation is required'),
        client_office_address: Yup.string().min(3).required('Office Address is required'),



    });


    $(".add-popup").on("click", function () {
        $('.toggle-popup').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
    });


    $(".detail-data").off().on("click", function () {
        alert('detail-data')
        const detailsId = $(this).attr('detail-data-id');
        alert(detailsId);
        navigate(`${apiUrl}/employee-contact-details?id=${detailsId}`);
        return false;
    });


   // $("#categorieslist_data_wrapper, .edit-popup").off().on("click", function () {   

   $(document).on("click", '.edit-popup', function () {
   
       // alert('edit id')
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        const id = $(this).attr('edit-data-id');
        handleEdit(id);
        return false;
    });



    const handleEdit = async (id) => {
        //alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/employee-contact-edit`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    setEditContact(response.data.data);
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };



    // Event handler for status action
    $("#categorieslist_data").off().on("click", '.statusaction', function () {
        const statusActionId = $(this).attr('status-action-data-id');
        const statusAction = $(this).attr('status-action');
        // Confirmation dialog

        const userConfirmed = window.confirm("Are you sure you want to proceed?");

        if (userConfirmed) {
            try {
                handleStatusAction(statusActionId, statusAction);
            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


        return false;
    });
    

    const handleStatusAction = async (id, statusAction) => {
        if (id) {
            try {
                const response = await axios.post(`${apiUrl}/employee-status`, {
                    id: `${id}`,
                    statusAction: `${statusAction}`
                });

                // Update the specific row in the DataTable's data set without reloading
                if (response.data && response.data.data) {
                    updateDataTableRow(id, response.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };


    

    $("#categorieslist_data_wrapper").off().on("click", '.change-pass', function () {
        alert('change password')
        setShowModal(true);
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();

        const hdneEmpId = $(this).attr('change-pass-data-id');
        alert(hdneEmpId)
        setHiddenEmpIdValue(hdneEmpId); // Update the hidden value dynamically
        return false;
    });



    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/countrylist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setCountries(response.data.data);
                    
                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    // Fetch states data based on selected country
    useEffect(() => {
        console.log("country id here ====================", selectedCountry)
        if (selectedCountry) {
            axios({
                method: 'POST',
                url: `${apiUrl}/statelist`,
                data: { country: `${selectedCountry}` }
            })
                .then(function (response) {
                    console.log(response)
                    if (Array.isArray(response.data.data)) {
                        setStates(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedCountry]);



    // Fetch cities data based on selected state
    useEffect(() => {
        console.log("state id here ====================", selectedState)
        if (selectedState) {
            axios({
                method: 'POST',
                url: `${apiUrl}/citylist`,
                data: { state: `${selectedState}` }
            })
                .then(function (response) {
                    console.log(response)
                    //alert('Successfully signed up!');
                    if (Array.isArray(response.data.data)) {
                        setCities(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedState]);



    useEffect(() => {
        const fetchReportingManagers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/emp-reporting-manager`);
                
                if (Array.isArray(response.data.data)) {
                    setReportingManager(response.data.data);  // Set fetched data as options
                } else {
                    console.error("Expected an array but got:", response.data);
                }
            } catch (error) {
                console.error("Error fetching reporting managers:", error);
            }
        };

        fetchReportingManagers();
    }, []);
    
    


    // Contact List data
    useEffect(() => {

        axios.get(`${apiUrl}/employee-contact-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "pk_emp_id" },
                                { title: "Category Type", data: "category_type" },
                                {
                                    title: "Full Name",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        
                                        let setImageName;
                                        if (row.profile_img) {
                                            setImageName = `${apiUrl}/writable/uploads/emp_contact_profile/` + row.profile_img;
                                        } else {
                                            setImageName = 'assets/img/profiles/avatar-29.png';
                                        }


                                        return `<h2 class="table-avatar d-flex align-items-center"><a href="contact-details" class="avatar"><img class="avatar-img" src=${setImageName} alt=""></a><a href="contact-details" class="profile-split d-flex flex-column">${row.first_name} ${row.last_name}</a></h2>`;
                                    }
                                },
                                { title: "DOB", data: "dob" },
                                { title: "Gender", data: "gender" },
                                { title: "Phone", data: "phone" },
                                { title: "Email", data: "email" },
                                { title: "Address", data: "address" },
                                 {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        console.log("status============", statusContact);
                                        if (row.is_active == 1) {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.pk_emp_id}" status-action="0"><span class='badge badge-pill badge-status bg-success'>Active</span></a>`;
                                        } else {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.pk_emp_id}" status-action="1"><span class='badge badge-pill badge-status bg-danger'>InActive</span></a>`;

                                        }
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                                        <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" edit-data-id="${row.pk_emp_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                                        <a class="dropdown-item change-pass" href="javascript:void(0);" change-pass-data-id="${row.pk_emp_id}"><i class="ti ti-key text-danger"></i> Change Password</a>
                                        </div></div>`;
                                    }
                                },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    const handleGetValue = () => {
        // Access the value of the input element
        //alert('dddd');
    };



    const handleClick = (id, event) => {
        event.preventDefault();
        console.log('Clicked ID:', id);
        alert('dddd');
        // Additional logic here
    };


    const handleOnSubmit = (values) => {
        const first_name = Object.keys(values)
            .map((key) => values[key])
            .join(" ");
        alert(`Hello ${first_name}!`);
    };



    const [formValues, setFormValues] = useState('');

    const setSelectValue = (field, value) => {
        alert(value);
        setFormValues({
            ...formValues,
            [field]: value,
        });
    };


    const handleImage2Change = (e) => {
        setImage2(e.target.files[0]);
    };

    const formik = useFormik({
        initialValues: {
            category_type                                  : '',
            source_of_contact                              : '',
            profile_img                                    : '',
            first_name                                     : '',
            last_name                                      : '',
            client_dob                                     : '',
            client_gender                                  : '',
            client_phone                                   : '',
            client_address                                 : '',
            client_state                                   : '',
            client_city                                    : '',
            client_country                                 : '',
            client_email                                   : '',
            client_job_title                               : '',
            department                                     : '',
            location                                       : '',
            client_designation                             : '',
            client_office_address                          : '',
            client_work_phone                              : '',
            client_work_email                              : '',
            work_country                                   : '',
            client_work_state                              : '',
            work_city                                      : '',
            client_linkedin_profile                        : '',
            client_reporting_manager                       : [],
            client_marital_status                          : '',
            client_name_of_spouse                          : '',
            client_spouse_dob                              : '',
            client_spouse_anniversary                      : '',
            client_no_of_kids                              : '',
            client_father_name                             : '',
            client_mother_name                             : '',
            client_ea_name                                 : '',
            client_pa_secretary_name                       : '',
            client_pa_secretary_phone                      : '',
            client_pa_secretary_email                      : '',
            client_hobbies                                 : '',
            client_favorite_sports                         : '',
            client_favorite_shows                          : '',
            client_favorite_brands                         : '',
            client_favorite_travel_destination             : '',
            client_other_interests                         : '',
            client_facebook                                : '',
            client_twitter                                 : '',
            client_instagram                               : '',
            client_other_relevant_social_media_platforms   : '',
            preferred_method_of_contact                    : '',
            client_pet_type                                : '',
            client_breed                                   : '',
            client_ailmments                               : '',
            client_allergies                               : '',
            client_comments                                : '',

        },
        validationSchema: schema,
        enableReinitialize: true,
        //onSubmit: handleOnSubmit,
        onSubmit: (values) => {
            console.log(values);

            const formData = new FormData();
            console.log("====client_marital_status=========", values.client_marital_status);
            // return false;

            formData.append('profile_img', image2);
            formData.append("category_type", values.category_type);
            formData.append("source_of_contact", values.source_of_contact);

            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("client_dob", values.client_dob);
            formData.append("client_gender", values.client_gender);
            formData.append("client_phone", values.client_phone);
            formData.append("client_email", values.client_email);
            formData.append("client_address", values.client_address);
            formData.append("client_country", values.client_country);
            formData.append("client_state", values.client_state);
            formData.append("client_city", values.client_city);
            formData.append("client_job_title", values.client_job_title);
            formData.append("department", values.department);
            formData.append("location", values.location);
            formData.append("client_designation", values.client_designation);
            formData.append("client_office_address", values.client_office_address);
            formData.append("client_work_phone", values.client_work_phone);
            formData.append("client_work_email", values.client_work_email);
            formData.append("client_work_country", values.work_country);
            formData.append("client_work_state", values.client_work_state);
            formData.append("client_work_city", values.work_city);
            formData.append("client_linkedin_profile", values.client_linkedin_profile);
            formData.append("client_reporting_manager", values.client_reporting_manager);
            formData.append("client_marital_status", values.client_marital_status);

            formData.append("client_name_of_spouse", values.name_of_spouse);
            formData.append("client_spouse_dob", values.client_spouse_dob);

            formData.append("client_spouse_anniversary", values.client_spouse_anniversary);
            formData.append("client_no_of_kids", values.client_no_of_kids);

            formData.append("client_father_name", values.client_father_name);
            formData.append("client_mother_name", values.client_mother_name);
            formData.append("client_ea_name", values.client_ea_name);
            formData.append("client_pa_secretary_name", values.client_pa_secretary_name);
            formData.append("client_pa_secretary_phone", values.client_pa_secretary_phone);
            formData.append("client_pa_secretary_email", values.client_pa_secretary_email);


            formData.append("client_hobbies", values.client_hobbies);
            formData.append("client_favorite_sports", values.client_favorite_sports);
            formData.append("client_favorite_shows", values.client_favorite_shows);
            formData.append("client_favorite_brands", values.client_favorite_brands);
            formData.append("client_favorite_travel_destination", values.client_favorite_travel_destination);
            formData.append("client_other_interests", values.client_other_interests);
            formData.append("client_facebook", values.client_facebook);
            formData.append("client_twitter", values.client_twitter);
            formData.append("client_instagram", values.client_instagram);
            formData.append("client_other_relevant_social_media_platforms", values.client_other_relevant_social_media_platforms);
            formData.append("preferred_method_of_contact", values.preferred_method_of_contact);

            formData.append("client_pet_type", values.client_pet_type);
            formData.append("client_breed", values.client_breed);

            formData.append("client_ailmments", values.client_ailmments);
            formData.append("client_allergies", values.client_allergies);
            formData.append("client_comments", values.client_comments);

            axios({
                method: 'POST',
                headers: { "Content-Type": "multipart/form-data" },
                url: `${apiUrl}/employee-register`,
                data: formData
            })
                .then(function (res) {
                    console.log(res.status)
                    if (res.status === 200) {
                        pageLoadData();
                        //$('#create_contact').addClass('d-block');
                        alert('Successfully added data');

                        $('.toggle-popup').removeClass('sidebar-popup');
                        $('.sidebar-popoverlay').removeClass('open');
                    }

                })
                .catch(function (res) {
                    console.log(res)
                });


        }

    });


    const pageLoadData = async () => {
        axios.post(`${apiUrl}/contactlist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "pk_emp_id" },
                                { title: "Category Type", data: "category_type" },
                                {
                                    title: "Full Name",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        if (row.profile_img != null) {
                                            var setImageName = '/writable/uploads/emp_contact_profile/' + row.profile_img;
                                        } else {
                                            //alert('else if condition 2222')
                                            var setImageName = 'assets/img/profiles/avatar-04.jpg';
                                        }



                                        return `<h2 class="table-avatar d-flex align-items-center"><a href="contact-details" class="avatar"><img class="avatar-img" src=${setImageName} alt=""></a><a href="contact-details" class="profile-split d-flex flex-column">${row.first_name} ${row.last_name}</a></h2>`;
                                    }
                                },
                                { title: "DOB", data: "dob" },
                                { title: "Gender", data: "gender" },
                                { title: "Phone", data: "phone" },
                                { title: "Email", data: "email" },
                                { title: "Address", data: "address" },
                                // { title: "Job Title", data: "job_title" },
                                // { title: "Designation", data: "designation" },
                                {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        return `<span class='badge badge-pill badge-status bg-success'>Active</span>`;
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                              <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" edit-data-id="${row.pk_emp_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                              <a class="dropdown-item change-pass" href="javascript:void(0);" change-pass-data-id="${row.pk_emp_id}"><i class="ti ti-key text-danger"></i> Change Password</a>
                              <a class="dropdown-item detail-data"  href="javascript:void(0);" detail-data-id="${row.pk_emp_id}" ><i class="ti ti-eye text-blue-light"></i> View</a>
                              </div></div>`;

                            

                                }
                            },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the contact list data!", error);
            });

    };






    const handleContactsNavigation = () => {
        navigate('/contacts');
    };

    const setInputValue = useCallback(
        (key, value) =>
            formik.setValues({
                ...formik.values,
                [key]: value,
            }),
        [formik]
    );



    const handleChange = (event) => {
        alert(event.target.value);
        setInputValue(event.target.value);
    };


 //######################################################## Edit Section Here ###############################//

    useEffect(() => {
        // Initialize the gender state with the value from editContact
        setGender(editContact.gender);
    }, [editContact]);

    useEffect(() => {
        // alert(editContact.country_id)
        setSelectedValue(editContact.country_id); // Dynamically set the default value
    }, []);


    useEffect(() => {
       
        if (editContact) {
            setSelectedCountry(editContact.country_id);
            setSelectedState(editContact.state_id);
            
            edit_formik.setValues({
                edit_profile_img: editContact.profile_img || '',
                hdn_edit_profile_img: editContact.profile_img || '',
                edit_pk_emp_id: editContact.pk_emp_id || '',
                edit_pk_emp_id1: editContact.pk_emp_id || '',
                edit_category_type: editContact.category_type || '',
                edit_first_name: editContact.first_name || '',
                edit_last_name: editContact.last_name || '',
                edit_dob: editContact.dob || '',
                edit_gender: editContact.gender || '',
                edit_phone: editContact.phone || '',
                edit_email: editContact.email || '',
                edit_address: editContact.address || '',
                edit_country: editContact.country_id || '',
                edit_state: editContact.state_id || '',
                edit_city: editContact.city_id || '',
                edit_job_title: editContact.job_title || '',
                emp_department: editContact.department || '',
                edit_location: editContact.location || '',
                edit_designation: editContact.designation || '',
                edit_work_address: editContact.work_address || '',
                edit_work_phone: editContact.work_phone || '',
                edit_work_email: editContact.work_email || '',
                edit_work_country: editContact.work_country || '',
                edit_work_state: editContact.work_state || '',
                edit_work_city: editContact.work_city || '',
                edit_linkedin_profile: editContact.linkedin_profile || '',
                edit_reporting_manager: editContact.reporting_manager || '',
                edit_marital_status: editContact.marital_status || '',
                edit_name_of_spouse: editContact.name_of_spouse || '',
                edit_spouse_dob: editContact.spouse_dob || '',
                edit_no_of_kids: editContact.no_of_kids || '',
                edit_spouse_anniversary: editContact.anniversary || '',
                edit_father_name: editContact.father_name || '',
                edit_mother_name: editContact.mother_name || '',
                edit_ea_name: editContact.ea_name || '',
                edit_pa_secretary_name: editContact.secretary_name || '',
                edit_pa_secretary_phone: editContact.secretary_phone || '',
                edit_secretary_phone: editContact.secretary_phone || '',
                edit_hobbies: editContact.hobbies || '',
                edit_favorite_sports: editContact.favorite_sports || '',
                edit_favorite_shows: editContact.favorite_shows || '',
                edit_favorite_brands: editContact.favorite_brands || '',
                edit_favorite_travel_destination: editContact.favorite_travel_destination || '',
                edit_other_interests: editContact.other_interests || '',
                edit_facebook: editContact.facebook || '',
                edit_twitter: editContact.twitter || '',
                edit_instagram: editContact.instagram || '',
                edit_other_relevant_social_media_platforms: editContact.other_relevant_social_media_platforms || '',
                edit_preferred_method_of_contact: editContact.preferred_method_of_contact || '',
                edit_pet_type: editContact.pet_type || '',
                edit_breed: editContact.pet_breed || '',
                edit_ailmments: editContact.health_parameters_ailmments || '',
                edit_allergies: editContact.health_parameters_allergies || '',
                edit_comments: editContact.additional_details_notes || '',

            });
        }
    }, [editContact]);


    // Validation schema using Yup
    const edit_schema = Yup.object({
        edit_category_type: Yup.string().required('Please select category type'),
        edit_first_name: Yup.string()
            .required('First name is required')
            .min(2, 'First name must be at least 2 characters'),
        edit_last_name: Yup.string().min(3).required('Last Name is required'),
        edit_dob: Yup.date()
            .nullable()
            .required("Date of birth is required"),

        edit_gender: Yup.string().required('Gender is required'),
        edit_phone: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            .required('Mobile number is required'),
        edit_email: Yup.string().email('Invalid email address').required('Email is required'),
        edit_address: Yup.string().min(3).required('Address is required'),
        edit_country: Yup.string().required('Interests are required'),
        edit_job_title: Yup.string().required('Job title are required'),



    });

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }




    // Formik setup
    const edit_formik = useFormik({
        initialValues: {
            edit_profile_img                              : '',
            hdn_edit_profile_img                          : '',
            edit_pk_emp_id                                : '',
            edit_pk_emp_id1                               : '',
            edit_category_type                            : '',
            edit_first_name                               : '',
            edit_last_name                                : '',
            edit_dob                                      : '',
            edit_gender                                   : '',
            edit_phone                                    : '',
            edit_email                                    : '',
            edit_address                                  : '',
            edit_country                                  : '',
            edit_state                                    : '',
            edit_city                                     : '',
            edit_job_title                                : '',
            edit_department                               : '',
            edit_location                                 : '',
            edit_designation                              : '',
            edit_office_address                           : '',
            edit_work_phone                               : '',
            edit_work_email                               : '',
            edit_work_country                             : '',
            edit_work_state                               : '',
            edit_work_city                                : '',
            edit_linkedin_profile                         : '',
            edit_reporting_manager                        : '',
            edit_marital_status                           : '',
            edit_name_of_spouse                           : '',
            edit_no_of_kids                               : '',
            edit_spouse_anniversary                       : '',
            edit_father_name                              : '',
            edit_mother_name                              : '',
            edit_ea_name                                  : '',
            edit_pa_secretary_name                        : '',
            edit_pa_secretary_phone                       : '',
            edit_pa_secretary_email                       : '',
            edit_hobbies                                  : '',
            edit_favorite_sports                          : '',
            edit_favorite_shows                           : '',
            edit_favorite_brands                          : '',
            edit_favorite_travel_destination              : '',
            edit_other_interests                          : '',
            edit_facebook                                 : '',
            edit_twitter                                  : '',
            edit_instagram                                : '',
            edit_other_relevant_social_media_platforms    : '',
            edit_preferred_method_of_contact              : '',
            edit_pet_type                                 : [],
            edit_breed                                    : [],
            edit_ailmments                                : '',
            edit_allergies                                : '',
            edit_comments                                 : '',
        },
        validationSchema: edit_schema,
        onSubmit: (values) => {
            alert('Form submitted!');

            console.log("===========dddddd=", values.profile_img);
            const formData = new FormData();
            formData.append("edit_profile_img", selectedFile);
            formData.append("hdn_edit_profile_img", values.hdn_edit_profile_img);

            formData.append("edit_pk_emp_id", values.edit_pk_emp_id);
            formData.append("edit_pk_emp_id1", values.edit_pk_emp_id1);
            formData.append("edit_category_type", values.edit_category_type);
            formData.append("edit_first_name", values.edit_first_name);
            formData.append("edit_last_name", values.edit_last_name);
            formData.append("edit_dob", values.edit_dob);
            formData.append("edit_gender", values.edit_gender);
            formData.append("edit_phone", values.edit_phone);
            formData.append("edit_email", values.edit_email);
            formData.append("edit_address", values.edit_address);
            formData.append("edit_country", values.edit_country);
            formData.append("edit_state", values.edit_state);
            formData.append("edit_city", values.edit_city);
            formData.append("edit_job_title", values.edit_job_title);
            formData.append("edit_department", values.edit_department);
            formData.append("edit_location", values.edit_location);
            formData.append("edit_designation", values.edit_designation);
            formData.append("edit_office_address", values.edit_office_address);
            formData.append("edit_work_phone", values.edit_work_phone);
            formData.append("edit_work_email", values.edit_work_email);
            formData.append("edit_work_country", values.edit_work_country);
            formData.append("edit_work_state", values.edit_work_state);
            formData.append("edit_work_city", values.edit_work_city);
            formData.append("edit_linkedin_profile", values.edit_linkedin_profile);
            
            formData.append("edit_reporting_manager", values.edit_reporting_manager);

            formData.append("edit_marital_status", values.edit_marital_status);
            formData.append("edit_name_of_spouse", values.edit_name_of_spouse);
            formData.append("edit_no_of_kids", values.edit_no_of_kids);

            formData.append("edit_spouse_dob", values.edit_spouse_dob);

            formData.append("edit_spouse_anniversary", values.edit_spouse_anniversary);
            formData.append("edit_father_name", values.edit_father_name);
            formData.append("edit_mother_name", values.edit_mother_name);
            formData.append("edit_ea_name", values.edit_ea_name);
            formData.append("edit_pa_secretary_name", values.edit_pa_secretary_name);
            formData.append("edit_pa_secretary_phone", values.edit_pa_secretary_phone);
            formData.append("edit_pa_secretary_email", values.edit_pa_secretary_email);

            formData.append("edit_hobbies", values.edit_hobbies);
            formData.append("edit_favorite_sports", values.edit_favorite_sports);
            formData.append("edit_favorite_shows", values.edit_favorite_shows);
            formData.append("edit_favorite_brands", values.edit_favorite_brands);
            formData.append("edit_favorite_travel_destination", values.edit_favorite_travel_destination);
            formData.append("edit_other_interests", values.edit_other_interests);
            formData.append("edit_facebook", values.edit_facebook);
            formData.append("edit_twitter", values.edit_twitter);
            formData.append("edit_instagram", values.edit_instagram);
            formData.append("edit_other_relevant_social_media_platforms", values.edit_other_relevant_social_media_platforms);

            formData.append("edit_preferred_method_of_contact", values.edit_preferred_method_of_contact);
            formData.append("edit_pet_type", values.edit_pet_type);
            formData.append("edit_breed", values.edit_breed);

            formData.append("edit_ailmments", values.edit_ailmments);
            formData.append("edit_allergies", values.edit_allergies);
            formData.append("edit_comments", values.edit_comments);

            console.log("===============" + values.edit_pk_emp_id1);
            axios({
                method: 'POST',
                url: `${apiUrl}/edit-employee-contact`,
                headers: { "Content-Type": "multipart/form-data" },
                //data: values
                data: formData,
            })
                .then(function (res) {
                    console.log(res)
                    alert('Data was successfully updated.');
                    $('.toggle-popup1').removeClass('sidebar-popup');
                    $('.sidebar-popoverlay').removeClass('open');
                    editDataTableRow(values.edit_pk_emp_id, res.data.data)
                    // $("#preview1").show();
                    setIsVisible(!isVisible); // Toggle visibility
                })
                .catch(function (res) {
                    console.log(res,)
                });

        },
    });


    const editDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };


    useEffect(() => {
        // Update the select element when formik values change
        const selectElement = document.getElementById('edit_pet_type');
        if (selectElement) {
            selectElement.value = formik.values.edit_pet_type;
        }
    }, [formik.values.edit_pet_type]);



    /**********************  Change Password *******************/
    // Formik setup

    const validationSchema = Yup.object({
        
        edit_new_password: Yup.string()
          .required('New password is required')
          .min(8, 'Password must be at least 8 characters long')
          .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
          .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
          .matches(/\d/, 'Password must contain at least one number')
          .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
        edit_confirm_password: Yup.string()
          .required('Please confirm your new password')
          .oneOf([Yup.ref('edit_new_password'), null], 'Passwords must match'),
      });   
   
const changepass_formik = useFormik({
    initialValues: {
        edit_new_password     : '',
        edit_confirm_password : '',
    },
    validationSchema,
    onSubmit: values => {
        console.log("hiidddd" ,hiddenEmpIdValue);
      // handle form submission
        console.log(changepass_formik.values); 
        const formData = new FormData();
        formData.append("change password", values.edit_new_password);
        formData.append("edit_hdn_emp_id", hiddenEmpIdValue);
       

      axios({
        method: 'POST',
        url: `${apiUrl}/change-emp-password`,
        headers: { "Content-Type": "multipart/form-data" },
        //data: values
        data: formData,
    })
        .then(function (res) {
            console.log(res)
            alert('Data was successfully updated.');
            //$('.toggle-popup1').removeClass('sidebar-popup');
           // $('.sidebar-popoverlay').removeClass('open');
            
            //editDataTableRow(values.edit_pk_emp_id, res.data.data)
            // $("#preview1").show();
            //setIsVisible(!isVisible); // Toggle visibility
        })
        .catch(function (res) {
            console.log(res,)
        });

    }
  });



    const options = [
        { value: 'Cat', label: 'Call' },
        { value: 'Dog', label: 'Dog' },
        { value: 'Others', label: 'Others' },
        { value: 'None', label: 'None' },
    ];


    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Employee Contacts<span className="count-title">{totalCount}</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    {/* <div className="search-section">
                              <div className="row">
                                 <div className="col-md-5 col-sm-4">
                                    <div className="form-wrap icon-form">

                                    </div>
                                 </div>
                                 <div className="col-md-7 col-sm-8">
                                    <div className="export-list text-sm-end">
                                       <ul>
                                          <li>
                                             <div className="export-dropdwon">
                                                <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                <div className="dropdown-menu  dropdown-menu-end">
                                                   <ul>
                                                      <li>
                                                         <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                      </li>
                                                      <li>
                                                         <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </div>
                                          </li>
                                          <li>
                                             <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Contacts</a>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div> */}


                                    <div className="filter-section filter-flex">
                                        <div className="sortby-list">
                                            <ul>

                                                <li>
                                                    <div className="form-wrap icon-form">
                                                        <span className="form-icon"><i className="ti ti-calendar"></i></span>
                                                        <input type="text" className="form-control bookingrange" placeholder />
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div className="export-dropdwon">
                                                        <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                        <div className="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Contacts</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="no-sort">
                                                        <label className="checkboxs">
                                                            <input type="checkbox" id="select-all" /><span className="checkmarks"></span></label>
                                                    </th>
                                                    <th className="no-sort"></th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Tags</th>
                                                    <th>Location</th>
                                                    <th>Rating</th>
                                                   
                                                    <th>Status</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="toggle-popup">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Add New Employee</h4>
                        <a href="#" className="sidebar-close toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">

                            <form onSubmit={formik.handleSubmit} >
                                <div className="accordion-lists" id="list-accord">


                                <div className={`user-accordion-item ${formik.errors.category_type ? 'highlight' : ''}`}>

                                        <a href="#" className="accordion-wrap" data-bs-toggle="collapse" data-bs-target="#contacttype"><span><i className="ti ti-user-plus"></i></span>Contact Type (Tags/Categories)</a>
                                        <div className="accordion-collapse collapse show" id="contacttype" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Category Type</label>

                                                            <select className="form-control" name="category_type" as="select" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.category_type} >
                                                                <option value="">Select Client Tags/Categories</option>
                                                                <option value="VIP">VIP</option>
                                                                <option value="High Priority">High Priority</option>
                                                                <option value="Potential Upsell">Potential Upsell</option>
                                                                <option value="Agent">Agent</option>
                                                                <option value="Supplier">Supplier</option>
                                                                <option value="Intern">Intern</option>
                                                                <option value="Competitor">Competitor</option>
                                                                <option value="Others">Others</option>
                                                            </select>
                                                            <small className="error">{formik.errors.category_type}</small>

                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-6">
                                          <div className="form-wrap">
                                             <label className="col-form-label">Source Of Contact</label>
                                             <select className="form-control" name="source_of_contact" as="select" onChange={formik.handleChange}  onBlur={formik.handleBlur} value={formik.values.source_of_contact} >
                                               <option value="">Select Source Of Contact</option>
                                                <option value="Exist Customer">Exist Customer</option>
                                                <option value="Traders">Traders</option>
                                                <option value="Google">Google</option>
                                                <option value="LinkedIn">LinkedIn</option>
                                                <option value="Website">Website</option>
                                                <option value="Referral">Referral</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Events and Networking">Events and Networking</option>
                                                
                                                <option value="Other">Other</option>
                                             </select>
                                             <small className="error">{formik.errors.source_of_contact}</small>

                                          </div>
                                       </div> */}


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="user-accordion-item"> */}    
                                    
                                    <div className={`user-accordion-item ${formik.errors.first_name || formik.errors.last_name || formik.errors.client_dob  || formik.errors.client_gender  || formik.errors.client_phone || formik.errors.client_email || formik.errors.client_address  || formik.errors.client_country  || formik.errors.client_state || formik.errors.client_city ? 'highlight' : ''}`}>

                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#basicinformation"><span><i className="ti ti-user-plus"></i></span>Basic Details</a>


                                        <div className="accordion-collapse collapse collapse" id="basicinformation" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">


                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <div className="profile-upload">
                                                                <div className="profile-upload-img">
                                                                    <span><i className="ti ti-photo"></i></span>
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-20.jpg`} alt="img" className="preview1" />
                                                                    <button type="button" className="profile-remove">
                                                                        <i className="ti ti-x"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="profile-upload-content">
                                                                    <label className="profile-upload-btn">
                                                                        <i className="ti ti-file-broken"></i> Upload File
                                                                        <input type="file" className="input-img" accept="image/*" onChange={handleImage2Change} />
                                                                    </label>
                                                                    <p>JPG, GIF or PNG. Max size of 800K</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                                                            <input className="form-control" name="first_name" value={formik.values.first_name} onChange={(e) => setInputValue("first_name", e.target.value)} />
                                                            <small className="error">{formik.errors.first_name}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="last_name" value={formik.values.last_name} onChange={(e) => setInputValue("last_name", e.target.value)} />
                                                            <small className="error">{formik.errors.last_name}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Date of Birth</label>
                                                            <input type="date" class="form-control" name="client_dob" placeholder="DD-MM-YY" value={formik.values.client_dob} onChange={(e) => setInputValue("client_dob", e.target.value)} />
                                                            <small className="error">{formik.errors.client_dob}</small>
                                                            {/* <input type="text" class="form-control datetimepicker" name="client_dob" placeholder="DD-MM-YY" value={formik.values.client_dob} onChange={handleChange} /> */}

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Gender <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="client_gender" as="select" value={formValues.client_gender} onChange={(e) => setInputValue('client_gender', e.target.value)} >
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                            <small className="error">{formik.errors.client_gender}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Phone <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="client_phone" value={formik.values.client_phone} onChange={(e) => setInputValue("client_phone", e.target.value)} />
                                                            <small className="error">{formik.errors.client_phone}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Personal Email <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="client_email" value={formik.values.client_email} onChange={(e) => setInputValue("client_email", e.target.value)} />
                                                            <small className="error">{formik.errors.client_email}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Address <span className="text-danger">*</span></label>
                                                            {/* <input type="text" class="form-control" name="client_address" value={formik.values.client_address} onChange={(e) => setInputValue("client_address", e.target.value)} /> */}
                                                            <textarea class="form-control" id="client_address" rows="3" name="client_address" value={formik.values.client_address} onChange={(e) => setInputValue("client_address", e.target.value)} ></textarea>
                                                            <small className="error">{formik.errors.client_address}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>
                                                            <select
                                                                className="form-control"
                                                                name="client_country"
                                                                as="select"
                                                                value={formik.values.client_country}
                                                                onChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    formik.handleChange(e); // Update Formik's internal state
                                                                    setSelectedCountry(e.target.value);
                                                                }}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{formik.errors.client_country}</small>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>

                                                            <select
                                                                className="form-control"
                                                                name="client_state"
                                                                value={formValues.client_state}
                                                                onChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    formik.handleChange(e); // Update Formik's internal state
                                                                    setSelectedState(e.target.value);
                                                                }}

                                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                                            >
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{formik.errors.client_state}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                                            <select disabled={!selectedState} className="form-control" name="client_city" as="select" value={formValues.client_city} onChange={(e) => setInputValue('client_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{formik.errors.client_city}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    

                                    <div className={`user-accordion-item ${formik.errors.client_job_title || formik.errors.department || formik.errors.location  || formik.errors.client_designation  || formik.errors.client_office_address || formik.errors.client_work_state  || formik.errors.work_city ? 'highlight' : ''}`}>

                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#professional_details"><span><i className="ti ti-map-pin-cog"></i></span>Professional Details</a>
                                        <div className="accordion-collapse collapse" id="professional_details" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Job Title <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_job_title" value={formik.values.client_job_title} onChange={(e) => setInputValue("client_job_title", e.target.value)} />
                                                            <small className="error">{formik.errors.client_job_title}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Department <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="department" value={formik.values.department} onChange={(e) => setInputValue("department", e.target.value)} />
                                                            <small className="error">{formik.errors.department}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Location <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="location" value={formik.values.location} onChange={(e) => setInputValue("location", e.target.value)} />
                                                            <small className="error">{formik.errors.location}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Designation <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_designation" value={formik.values.client_designation} onChange={(e) => setInputValue("client_designation", e.target.value)} />
                                                            <small className="error">{formik.errors.client_designation}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Office Address <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_office_address" value={formik.values.client_office_address} onChange={(e) => setInputValue("client_office_address", e.target.value)} />
                                                            <small className="error">{formik.errors.client_office_address}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>
                                                            {/* <select className="form-control" as='select' name="work_country" value={formValues.work_country} onChange={(e) => setInputValue('work_country', e.target.value)}>
                                                            <option value="">Select Country</option>
                                                            <option value="1">Afghanistan</option>
                                                            <option value="2">Albania</option>
                                                            <option value="3">Algeria</option>
                                                            <option value="3">India</option>
                                                            </select> */}

                                                            <select className="form-control" name="work_country" as="select"
                                                                value={formik.values.work_country}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}>
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>
                                                            <select
                                                                className="form-control"
                                                                name="client_work_state"
                                                                value={formValues.client_work_state}
                                                                onChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    formik.handleChange(e); // Update Formik's internal state
                                                                    setSelectedState(e.target.value);
                                                                }}

                                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                                            >
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{formik.errors.client_work_state}</small>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work City <span className="text-danger">*</span></label>
                                                            {/* <select className="form-control" as="select" name="work_city" value={formValues.work_city} onChange={(e) => setInputValue('work_city', e.target.value)}>
                                                            <option value="">Select City</option>
                                                            <option value="1">Mumbai</option>
                                                            <option value="2">Pune</option>
                                                            <option value="3">Delhi</option>
                                                            </select> */}

                                                            <select disabled={!selectedState} className="form-control" name="work_city" as="select" value={formValues.work_city} onChange={(e) => setInputValue('work_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{formik.errors.work_city}</small>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Phone <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_work_phone" value={formik.values.client_work_phone} onChange={(e) => setInputValue("client_work_phone", e.target.value)} />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Email <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_work_email" value={formik.values.client_work_email} onChange={(e) => setInputValue("client_work_email", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">LinkedIn Profile <span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name="client_linkedin_profile" value={formik.values.client_linkedin_profile} onChange={(e) => setInputValue("client_linkedin_profile", e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#account_mapping"><span><i className="ti ti-map-pin-cog"></i></span>Reporting</a>
                                        <div className="accordion-collapse collapse" id="account_mapping" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Reporting Manager</label>
                                                            {/* <select class="multiple-img" multiple="multiple">
                                                                <option data-image="assets/img/profiles/avatar-18.jpg" selected>Jerald</option>
                                                                <option data-image="assets/img/profiles/avatar-20.jpg">Sharon Roy</option>
                                                                <option data-image="assets/img/profiles/avatar-21.jpg">Vaughan</option>
                                                                <option data-image="assets/img/profiles/avatar-23.jpg">Jessica</option>
                                                                <option data-image="assets/img/profiles/avatar-16.jpg">Carol Thomas</option>
                                                            </select> */}

                                                    <select
                                                            className="form-control"
                                                            name="client_reporting_manager"
                                                            value={formik.values.client_reporting_manager}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">Select Reporting Manager</option>
                                                            {reportingManager.map(manager => (
                                                                <option key={manager.pk_emp_id} value={manager.pk_emp_id}>
                                                                    {manager.first_name} {manager.last_name}
                                                                </option>
                                                            ))}
                                                        </select>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#personal_details"><span><i className="ti ti-social"></i></span>Personal Details</a>
                                        <div className="accordion-collapse collapse" id="personal_details" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Marital Status</label>
                                                            <select className="form-control" name="client_marital_status" value={formik.values.client_marital_status} onChange={(e) => setInputValue('client_marital_status', e.target.value)}>
                                                                <option value="">Select Marital Status</option>
                                                                <option value="Married">Married</option>
                                                                <option value="Single">Single</option>
                                                                <option value="Single">Others</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Name of Spouse</label>
                                                            <input type="text" class="form-control" name="name_of_spouse" value={formik.values.name_of_spouse} onChange={(e) => setInputValue("name_of_spouse", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Spouse Date of Birth</label>
                                                            <input type="date" class="form-control" name="client_spouse_dob" placeholder="DD-MM-YY" value={formik.values.client_spouse_dob} onChange={(e) => setInputValue("client_spouse_dob", e.target.value)} />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Anniversary</label>
                                                            <input type="date" class="form-control" placeholder="DD-MM-YY" name="client_spouse_anniversary" value={formik.values.client_spouse_anniversary} onChange={(e) => setInputValue("client_spouse_anniversary", e.target.value)} />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">No. of Kids</label>
                                                            <select className="form-control" name="client_no_of_kids" alue={formik.values.client_no_of_kids} onChange={(e) => setInputValue("client_no_of_kids", e.target.value)}>
                                                                <option value="">Select No. of kids</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="More than 4">More than 4</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Father Name</label>
                                                            <input type="text" className="form-control" name="client_father_name" value={formik.values.client_father_name} onChange={(e) => setInputValue("client_father_name", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Mother Name</label>
                                                            <input type="text" className="form-control" name="client_mother_name" value={formik.values.client_mother_name} onChange={(e) => setInputValue("client_mother_name", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">EA Name</label>
                                                            <input type="text" class="form-control" name="client_ea_name" value={formik.values.client_ea_name} onChange={(e) => setInputValue("client_ea_name", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Name</label>
                                                            <input type="text" class="form-control" name="client_pa_secretary_name" value={formik.values.client_pa_secretary_name} onChange={(e) => setInputValue("client_pa_secretary_name", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Phone</label>
                                                            <input type="text" class="form-control" name="client_pa_secretary_phone" value={formik.values.client_pa_secretary_phone} onChange={(e) => setInputValue("client_pa_secretary_phone", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Email</label>
                                                            <input type="text" class="form-control" name="client_pa_secretary_email" value={formik.values.client_pa_secretary_email} onChange={(e) => setInputValue("client_pa_secretary_email", e.target.value)} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#personal_interests"><span><i className="ti ti-social"></i></span>Personal Interests</a>
                                        <div className="accordion-collapse collapse" id="personal_interests" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Hobbies</label>
                                                            <input type="text" class="form-control" name="client_hobbies" value={formik.values.client_hobbies} onChange={(e) => setInputValue("client_hobbies", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Sports</label>
                                                            <input type="text" class="form-control" name="client_favorite_sports" value={formik.values.client_favorite_sports} onChange={(e) => setInputValue("client_favorite_sports", e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Books/Movies/TV Shows</label>
                                                            <input type="text" class="form-control" name="client_favorite_shows" value={formik.values.client_favorite_shows} onChange={(e) => setInputValue("client_favorite_shows", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Brands</label>
                                                            <input type="text" class="form-control" name="client_favorite_brands" value={formik.values.client_favorite_brands} onChange={(e) => setInputValue("client_favorite_brands", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Travel Destination</label>
                                                            <input type="text" class="form-control" name="client_favorite_travel_destination" value={formik.values.client_favorite_travel_destination} onChange={(e) => setInputValue("client_favorite_travel_destination", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other Interests</label>
                                                            <input type="text" class="form-control" name="client_other_interests" value={formik.values.client_other_interests} onChange={(e) => setInputValue("client_other_interests", e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#social_media_profiles"><span><i className="ti ti-accessible"></i></span>Social Media Profiles</a>
                                        <div className="accordion-collapse collapse" id="social_media_profiles" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Facebook</label>
                                                            <input type="text" class="form-control" name="client_facebook" value={formik.values.client_facebook} onChange={(e) => setInputValue("client_facebook", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Twitter</label>
                                                            <input type="text" class="form-control" name="client_twitter" value={formik.values.client_twitter} onChange={(e) => setInputValue("client_twitter", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Instagram</label>
                                                            <input type="text" class="form-control" name="client_instagram" value={formik.values.client_instagram} onChange={(e) => setInputValue("client_instagram", e.target.value)} />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other relevant social media platforms</label>
                                                            <input type="text" class="form-control" name="client_other_relevant_social_media_platforms" value={formik.values.client_other_relevant_social_media_platforms} onChange={(e) => setInputValue("client_other_relevant_social_media_platforms", e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="user-accordion-item">
                              <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#communication_preferences"><span><i className="ti ti-accessible"></i></span>Communication Preferences</a>
                              <div className="accordion-collapse collapse" id="communication_preferences" data-bs-parent="#list-accord">
                                 <div className="content-collapse">
                                    <div className="row">
                                       <div className="col-md-12">
                                          <div className="form-wrap">
                                             <label className="col-form-label">Preferred Method of Contact</label>
                                             <select className="form-control" name="preferred_method_of_contact" alue={formik.values.preferred_method_of_contact} onChange={(e) => setInputValue("preferred_method_of_contact", e.target.value)}>
                                                <option value="">Select Preferred Method of Contact</option>
                                                <option value="Call">Call</option>
                                                <option value="Message / Whatsapp">Message / Whatsapp</option>
                                                <option value="Email">Email</option>
                                                <option value="In-person">In-person</option>
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div> */}

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#pets"><span><i className="ti ti-accessible"></i></span>Pets</a>
                                        <div className="accordion-collapse collapse" id="pets" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <label className="col-form-label">Type</label>
                                                    {/* <select class="multiple-img" multiple="multiple">
                                          <option data-image="assets/img/profiles/cat.jpg" value="Cat">Cat</option>
                                          <option data-image="assets/img/profiles/dog.jpg" value="Dog">Dog</option>
                                          <option data-image="assets/img/profiles/avatar-19.jpg" value="Others">Others</option>
                                          <option data-image="assets/img/profiles/avatar-19.jpg" value="None">None</option>
                                       </select> */}
                                                    <Select
                                                        id="client_pet_type"
                                                        name="client_pet_type"
                                                        options={options}
                                                        isMulti
                                                        value={options.filter(option => formik.values.client_pet_type.includes(option.value))}
                                                        onChange={(selectedOptions) => {
                                                            formik.setFieldValue(
                                                                'client_pet_type',
                                                                selectedOptions ? selectedOptions.map(option => option.value) : []
                                                            );
                                                        }}
                                                    />

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Breed<span className="text-danger">*</span></label>
                                                            {/* <select class="multiple-img" multiple="multiple">
                                                <option data-image="assets/img/profiles/cat.jpg" value="Cat">Cat</option>
                                                <option data-image="assets/img/profiles/dog.jpg" value="Dog">Dog</option>
                                                <option data-image="assets/img/profiles/avatar-19.jpg" value="Others">Others</option>
                                                <option data-image="assets/img/profiles/avatar-19.jpg" value="None">None</option>
                                             </select> */}

                                                            <Select
                                                                id="client_breed"
                                                                name="client_breed"
                                                                options={options}
                                                                isMulti
                                                                value={options.filter(option => formik.values.client_breed.includes(option.value))}
                                                                onChange={(selectedOptions) => {
                                                                    formik.setFieldValue(
                                                                        'client_breed',
                                                                        selectedOptions ? selectedOptions.map(option => option.value) : []
                                                                    );
                                                                }}
                                                            />


                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#health_parameters"><span><i className="ti ti-accessible"></i></span>Health Parameters</a>
                                        <div className="accordion-collapse collapse" id="health_parameters" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Ailments</label>
                                                            <input type="text" class="form-control" name="client_ailmments" value={formik.values.client_ailmments} onChange={(e) => setInputValue("client_ailmments", e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Allergies</label>
                                                            <input type="text" class="form-control" name="client_allergies" value={formik.values.client_allergies} onChange={(e) => setInputValue("client_allergies", e.target.value)} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#additionaldetails"><span><i className="ti ti-accessible"></i></span>Additional Details</a>
                                        <div className="accordion-collapse collapse" id="additionaldetails" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Notes/Comments:</label>
                                                            <input type="text" class="form-control" name="client_comments:" value={formik.values.client_comments} onChange={(e) => setInputValue("client_comments", e.target.value)} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close">Cancel</a>
                                    <button type="submit" className="btn btn-primary">
                                        Create
                                    </button>
                                    {/* <a href="#" data-bs-toggle="modal" data-bs-target="#create_contact" className="btn btn-primary">Create</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Contact</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_pk_emp_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_emp_id1} />
                                   <div className="accordion-lists" id="list-accords">
                                    
                                   
                                    <div className={`user-accordion-item ${edit_formik.errors.edit_category_type ? 'highlight' : ''}`}>

                                        <a href="#" className="accordion-wrap" data-bs-toggle="collapse" data-bs-target="#contacttype"><span><i className="ti ti-user-plus"></i></span>Contact Type (Tags/Categories)</a>
                                        <div className="accordion-collapse collapse show" id="contacttype" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Category Type</label>
                                                            <select className="form-control" as="select" name="edit_category_type" onChange={edit_formik.handleChange} >
                                                                <option value="">Select Client Tags/Categories</option>
                                                                <option value="VIP" selected={'VIP' === editContact.category_type}>VIP</option>
                                                                <option value="High Priority" selected={'High Priority' === editContact.category_type}>High Priority</option>
                                                                <option value="Potential Upsell" selected={'Potential Upsell' === editContact.category_type}>Potential Upsell</option>
                                                                <option value="Agent" selected={'Agent' === editContact.category_type}>Agent</option>
                                                                <option value="Supplier" selected={'Supplier' === editContact.category_type}>Supplier</option>
                                                                <option value="Intern" selected={'Intern' === editContact.category_type}>Intern</option>
                                                                <option value="Competitor" selected={'Competitor' === editContact.category_type}>Competitor</option>
                                                                <option value="Others" selected={'Others' === editContact.category_type}>Others</option>
                                                            </select>
                                                            <small className="error">{edit_formik.errors.edit_category_type}</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`user-accordion-item ${edit_formik.errors.edit_first_name 
                                         || edit_formik.errors.edit_last_name
                                         || edit_formik.errors.edit_dob
                                         || edit_formik.errors.edit_gender
                                         || edit_formik.errors.edit_phone
                                         || edit_formik.errors.edit_email
                                         || edit_formik.errors.edit_address
                                         || edit_formik.errors.edit_country
                                        ? 'highlight' : ''}`}>
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#editBasicInformation"><span><i className="ti ti-user-plus"></i></span>Basic Details</a>

                                        <div className="accordion-collapse collapse" id="editBasicInformation" data-bs-parent="#list-accords">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <div className="profile-upload">
                                                                <div className="profile-upload-img">
                                                                    <span><i className="ti ti-photo"></i></span>
                                                                    <input type="hidden" name="hdn_edit_profile_img" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.hdn_edit_profile_img} />
                                                                       

                                                                    <img src={`/writable/uploads/emp_contact_profile/${edit_formik.values.edit_profile_img}`} alt="" className="preview1" style={{ display: edit_formik.values.edit_profile_img ? 'block' : 'none' }} />

                                                                    <button type="button" className="profile-remove">
                                                                        <i className="ti ti-x"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="profile-upload-content">
                                                                    <label className="profile-upload-btn">
                                                                        <i className="ti ti-file-broken"></i> Upload File
                                                                        <input type="file" className="input-img" onChange={handleFileSelect} />
                                                                    </label>
                                                                    <p>JPG, GIF or PNG. Max size of 800K</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_first_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_first_name} />
                                                            <small className="error">{edit_formik.errors.edit_first_name}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_last_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_last_name} />
                                                            <small className="error">{edit_formik.errors.edit_last_name}</small>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Date of Birth <span className="text-danger">*</span></label>
                                                            {/* <input type="date" className="form-control" name="edit_dob" value={editContact.dob} onChange={(e) => setInputValue("edit_dob", e.target.value)} /> */}
                                                            <input type="date" className="form-control" name="edit_dob" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_dob} />
                                                            <small className="error">{edit_formik.errors.edit_dob}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Gender <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_gender" as="select" onChange={edit_formik.handleChange}   >
                                                                <option value="">Select Gender</option>
                                                                <option value="Male" selected={'Male' === editContact.gender}>Male</option>
                                                                <option value="Female" selected={'Female' === editContact.gender}>Female</option>
                                                            </select>
                                                            <small className="error">{edit_formik.errors.edit_gender}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Phone <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_phone} />
                                                            <small className="error">{edit_formik.errors.edit_phone}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Email <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_email" value={edit_formik.values.edit_email} onChange={edit_formik.handleChange} />
                                                            <small className="error">{edit_formik.errors.edit_email}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Address <span className="text-danger">*</span></label>
                                                            <textarea class="form-control" id="client_address" rows="3" name="edit_address" value={edit_formik.values.edit_address} onChange={edit_formik.handleChange} ></textarea>
                                                            <small className="error">{edit_formik.errors.edit_address}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_country" as="select" value={edit_formik.values.edit_country} onBlur={edit_formik.handleBlur} onChange={edit_formik.handleChange} >
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{edit_formik.errors.edit_country}</small>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_state" as="select" value={editContact.state_id} onChange={e => setSelectedState(e.target.value)}>
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                                            <select disabled={!selectedState} className="form-control" name="edit_city" as="select" value={editContact.city_id} onChange={(e) => setInputValue('edit_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`user-accordion-item ${edit_formik.errors.edit_job_title 
                                         || edit_formik.errors.edit_last_name
                                        
                                        ? 'highlight' : ''}`}>

                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit-address"><span><i className="ti ti-map-pin-cog"></i></span>Professional Details</a>
                                        <div className="accordion-collapse collapse" id="edit-address" data-bs-parent="#list-accords">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Job Title <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_job_title" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_job_title} />
                                                            <small className="error">{edit_formik.errors.edit_job_title}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Department<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="emp_department" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_department} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Location<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_location" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_location} />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Designation <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_designation" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_designation} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Office Address <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_office_address" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_office_address} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>

                                                            <select className="form-control" name="edit_work_country" as="select" value={edit_formik.work_country} onChange={formik.handleChange}>
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_work_state" as="select" value={edit_formik.work_state} onChange={e => setSelectedState(e.target.value)} disabled={!selectedCountry}>
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work City <span className="text-danger">*</span></label>
                                                            <select disabled={!selectedState} className="form-control" name="edit_work_city" as="select" value={edit_formik.work_city} onChange={(e) => setInputValue('edit_work_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Phone <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_work_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_work_phone} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Email <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_work_email" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_work_email} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">LinkedIn Profile <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_linkedin_profile" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_linkedin_profile} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_account_mapping"><span><i className="ti ti-map-pin-cog"></i></span>Reporting</a>
                                        <div className="accordion-collapse collapse" id="edit_account_mapping" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Reporting Manager</label>
                                                           
                                                             {/* <select class="multiple-img" multiple="multiple">
                                                                <option data-image="assets/img/profiles/avatar-18.jpg" selected>Jerald</option>
                                                                <option data-image="assets/img/profiles/avatar-20.jpg">Sharon Roy</option>
                                                                <option data-image="assets/img/profiles/avatar-21.jpg">Vaughan</option>
                                                                <option data-image="assets/img/profiles/avatar-23.jpg">Jessica</option>
                                                                <option data-image="assets/img/profiles/avatar-16.jpg">Carol Thomas</option>
                                                            </select> */}

                                                            <select className="form-control" name="edit_reporting_manager" as="select" value={edit_formik.values.edit_reporting_manager} onBlur={edit_formik.handleBlur} onChange={edit_formik.handleChange} >
                                                                 <option value="">Select Reporting Manager</option>
                                                                {reportingManager && reportingManager.map((reportingMng, cases) => (
                                                                <option key={reportingMng.pk_emp_id} value={reportingMng.pk_emp_id}>{reportingMng.first_name} {reportingMng.last_name}</option>
                                                            ))}
                                                            </select>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_personal_details"><span><i className="ti ti-social"></i></span>Personal Details</a>
                                        <div className="accordion-collapse collapse" id="edit_personal_details" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Marital Status</label>

                                                            <select className="form-control" as="select" name="edit_marital_status" onChange={edit_formik.handleChange} >
                                                                <option value="">Select Marital Status</option>
                                                                <option value="Married" selected={'Married' === editContact.marital_status}>Married</option>
                                                                <option value="Single" selected={'Single' === editContact.marital_status}>Single</option>
                                                                <option value="Others" selected={'Others' === editContact.marital_status}>Others</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Name of Spouse</label>
                                                            <input type="text" className="form-control" name="edit_name_of_spouse" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_name_of_spouse} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Spouse Date of Birth</label>
                                                            {/* <input type="text" class="form-control datetimepicker" name="edit_spouse_dob" placeholder="DD/MM/YY" value={editContact.spouse_dob} onChange={(e) => setInputValue("edit_spouse_dob", e.target.value)} /> */}

                                                            <input type="date" className="form-control" placeholder="DD/MM/YY" name="edit_spouse_dob" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_spouse_dob} />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Anniversary</label>
                                                            {/* <input type="text" class="form-control datetimepicker" name="edit_spouse_anniversary" value={editContact.spouse_anniversary} onChange={(e) => setInputValue("edit_spouse_anniversary", e.target.value)} /> */}

                                                            <input type="date" className="form-control" placeholder="DD/MM/YY" name="edit_spouse_anniversary" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_spouse_anniversary} />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">No. of Kids</label>
                                                            <select className="form-control" as="select" name="edit_no_of_kids" onChange={edit_formik.handleChange} >
                                                                <option value="">Select No. of kids</option>
                                                                <option value="1" selected={'1' === editContact.no_of_kids}>1</option>
                                                                <option value="2" selected={'2' === editContact.no_of_kids}>2</option>
                                                                <option value="3" selected={'3' === editContact.no_of_kids}>3</option>
                                                                <option value="4" selected={'4' === editContact.no_of_kids}>4</option>
                                                                <option value="More than 4" selected={'More than 4' === editContact.no_of_kids}>More than 4</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Father Name</label>
                                                            <input type="text" className="form-control" name="edit_father_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_father_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Mother Name</label>
                                                            <input type="text" className="form-control" name="edit_mother_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_mother_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">EA Name</label>
                                                            <input type="text" className="form-control" name="edit_ea_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_ea_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Name</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Phone</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_phone} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Email</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_email" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_email} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_personal_interests"><span><i className="ti ti-social"></i></span>Personal Interests</a>
                                        <div className="accordion-collapse collapse" id="edit_personal_interests" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Hobbies</label>
                                                            <input type="text" className="form-control" name="edit_hobbies" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_hobbies} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Sports</label>
                                                            <input type="text" className="form-control" name="edit_favorite_sports" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_sports} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Books/Movies/TV Shows</label>
                                                            <input type="text" className="form-control" name="edit_favorite_shows" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_shows} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Brands</label>
                                                            <input type="text" className="form-control" name="edit_favorite_brands" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_brands} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Travel Destination</label>
                                                            <input type="text" className="form-control" name="edit_favorite_travel_destination" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_travel_destination} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other Interests</label>
                                                            <input type="text" className="form-control" name="edit_other_interests" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_other_interests} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_social_media_profiles"><span><i className="ti ti-accessible"></i></span>Social Media Profiles</a>
                                        <div className="accordion-collapse collapse" id="edit_social_media_profiles" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Facebook</label>
                                                            <input type="text" className="form-control" name="edit_facebook" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_facebook} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Twitter</label>
                                                            <input type="text" className="form-control" name="edit_twitter" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_twitter} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Instagram</label>
                                                            <input type="text" className="form-control" name="edit_instagram" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_instagram} />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other relevant social media platforms</label>
                                                            <input type="text" className="form-control" name="edit_other_relevant_social_media_platforms" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_other_relevant_social_media_platforms} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_pets"><span><i className="ti ti-accessible"></i></span>Pets</a>
                                        <div className="accordion-collapse collapse" id="edit_pets" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="col-form-label">Type</label>
                                                       

                                                        <Select
                                                            id="edit_pet_type"
                                                            name="edit_pet_type"
                                                            options={options}
                                                            isMulti
                                                            value={options.filter(option => edit_formik.values.edit_pet_type.includes(option.value))}
                                                            onChange={(selectedOptions) => {
                                                                edit_formik.setFieldValue(
                                                                    'edit_pet_type',
                                                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                                                );
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Breed<span className="text-danger">*</span></label>
                                                           
                                                            <Select
                                                                id="edit_breed"
                                                                name="edit_breed"
                                                                options={options}
                                                                isMulti
                                                                value={options.filter(option => edit_formik.values.edit_breed.includes(option.value))}
                                                                onChange={(selectedOptions) => {
                                                                    edit_formik.setFieldValue(
                                                                        'edit_breed',
                                                                        selectedOptions ? selectedOptions.map(option => option.value) : []
                                                                    );
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_health_parameters"><span><i className="ti ti-accessible"></i></span>Health Parameters</a>
                                        <div className="accordion-collapse collapse" id="edit_health_parameters" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Ailments</label>
                                                            <input type="text" className="form-control" name="edit_ailmments" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_ailmments} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Allergies</label>
                                                            <input type="text" className="form-control" name="edit_allergies" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_allergies} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_additionaldetails"><span><i className="ti ti-accessible"></i></span>Additional Details</a>
                                        <div className="accordion-collapse collapse" id="edit_additionaldetails" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Notes/Comments:</label>
                                                            <input type="text" className="form-control" name="edit_comments" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_comments} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close1">Cancel</a>
                                    <button type="submit" className="btn btn-primary">
                                        Save Changes
                                    </button>

                                    {/* <a href="#" className="btn btn-primary">Save Changes</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="toggle-popup2">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Add New Deals</h4>
                        <a href="#" className="sidebar-close2 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <form action="contacts.html" className="toggle-height">
                            <div className="pro-create">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Deal Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Pipeine <span className="text-danger">*</span></label>
                                            </div>
                                            <select className="select2">
                                                <option>Choose</option>
                                                <option>Sales</option>
                                                <option>Marketing</option>
                                                <option>Calls</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Status <span className="text-danger">*</span></label>
                                            <select className="select2">
                                                <option>Choose</option>
                                                <option>Open</option>
                                                <option>Lost</option>
                                                <option>Won</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Deal Value<span className="text-danger"> *</span></label>
                                            <input className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Currency <span className="text-danger">*</span></label>
                                            <select className="select">
                                                <option>Select</option>
                                                <option>$</option>
                                                <option>€</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Period <span className="text-danger">*</span></label>
                                            <select className="select">
                                                <option>Choose</option>
                                                <option>Days</option>
                                                <option>Month</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Period Value <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Contact <span className="text-danger">*</span></label>
                                            <select className="multiple-img" multiple="multiple">
                                                <option data-image="assets/img/profiles/avatar-19.jpg" selected>Darlee Robertson</option>
                                                <option data-image="assets/img/profiles/avatar-20.jpg">Sharon Roy</option>
                                                <option data-image="assets/img/profiles/avatar-21.jpg">Vaughan</option>
                                                <option data-image="assets/img/profiles/avatar-23.jpg">Jessica</option>
                                                <option data-image="assets/img/profiles/avatar-16.jpg">Carol Thomas</option>
                                            </select>
                                        </div>
                                        <div className="form-wrap">
                                            <label className="col-form-label">Project <span className="text-danger">*</span></label>
                                            <select className="select" multiple="multiple">
                                                <option selected>Devops Design</option>
                                                <option selected>MargrateDesign</option>
                                                <option selected>UI for Chat</option>
                                                <option>Web Chat</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Due Date <span className="text-danger">*</span></label>
                                            <div className="icon-form">
                                                <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                                                <input type="text" className="form-control datetimepicker" placeholder="DD/MM/YY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Expected Closing Date <span className="text-danger">*</span></label>
                                            <div className="icon-form">
                                                <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                                                <input type="text" className="form-control datetimepicker" placeholder="DD/MM/YY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Assignee <span className="text-danger">*</span></label>
                                            <select className="multiple-img" multiple="multiple">
                                                <option data-image="assets/img/profiles/avatar-19.jpg">Darlee Robertson</option>
                                                <option data-image="assets/img/profiles/avatar-20.jpg" selected>Sharon Roy</option>
                                                <option data-image="assets/img/profiles/avatar-21.jpg">Vaughan</option>
                                                <option data-image="assets/img/profiles/avatar-23.jpg">Jessica</option>
                                                <option data-image="assets/img/profiles/avatar-16.jpg">Carol Thomas</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Follow Up Date <span className="text-danger">*</span></label>
                                            <div className="icon-form">
                                                <span className="form-icon"><i className="ti ti-calendar-check"></i></span>
                                                <input type="text" className="form-control datetimepicker" placeholder="DD/MM/YY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Source <span className="text-danger">*</span></label>
                                            <select className="select">
                                                <option>Select</option>
                                                <option>Google</option>
                                                <option>Social Media</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Tags <span className="text-danger">*</span></label>
                                            <input className="input-tags form-control" type="text" data-role="tagsinput" name="Label" value="Collab, Rated" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Priority <span className="text-danger">*</span></label>
                                            <select className="select">
                                                <option>Select</option>
                                                <option>Highy</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label">Description <span className="text-danger">*</span></label>
                                            <div className="summernote"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close2">Cancel</a>
                                    <button type="submit" className="btn btn-primary">Create</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" ref={modalRef} id="change_password" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Change Password</h5>
                            <button class="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <i class="ti ti-x"></i>
                            </button>
                        </div>
                       <form onSubmit={changepass_formik.handleSubmit} >
                       
                            <div class="modal-body">
                            <div class="mb-3">
                                    <label class="col-form-label">Old Password: xxxx</label>
                                </div>
                                <div class="mb-3">
                                    <label class="col-form-label">New Password <span class="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="edit_new_password" onChange={changepass_formik.handleChange} onBlur={changepass_formik.handleBlur} value={changepass_formik.values.edit_new_password} />
                                    <small className="error">{changepass_formik.errors.edit_new_password}</small>
                                </div>
                                <div class="mb-3">
                                    <label class="col-form-label">Confirm Password <span class="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="edit_confirm_password" onChange={changepass_formik.handleChange} onBlur={changepass_formik.handleBlur} value={changepass_formik.values.edit_confirm_password} />
                                    <small className="error">{changepass_formik.errors.edit_confirm_password}</small>
                                </div>
                                <div class="mb-0">
                                    <label class="col-form-label">Status</label>
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <input type="radio" class="status-radio" id="add-active" name="status" checked />
                                                <label for="add-active">Active</label>
                                        </div>
                                        <div>
                                            <input type="radio" class="status-radio" id="add-inactive" name="status" />
                                                <label for="add-inactive">Inactive</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="d-flex align-items-center justify-content-end m-0">
                                    <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" class="btn btn-primary">Update Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className="modal custom-modal fade" id="create_contact" role="dialog" style={{ opacity: 1 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 m-0 justify-content-end">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-light-blue">
                                    <i className="ti ti-user-plus"></i>
                                </div>
                                <h3>Contact Created Successfully!!!</h3>
                                <p>View the details of contact, created</p>
                                <div className="col-lg-12 text-center modal-btn">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    {/* <a href="/contacts" re className="btn btn-primary">View Details</a> */}
                                    <button onClick={handleContactsNavigation} className="btn btn-primary">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal custom-modal fade" id="save_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New View</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap">
                                    <label className="col-form-label">View Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-danger">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" id="access_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Access For</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap icon-form">
                                    <span className="form-icon"><i className="ti ti-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>
                                <div className="access-wrap">
                                    <ul>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-19.jpg`} alt />
                                                    <a href="#">Darlee Robertson</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-20.jpg`} alt />
                                                    <a href="#">Sharon Roy</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-21.jpg`} alt />
                                                    <a href="#">Vaughan</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-01.jpg`} alt />
                                                    <a href="#">Jessica</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-16.jpg`} alt />
                                                    <a href="#">Carol Thomas</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs" >
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-22.jpg`} alt />
                                                    <a href="#">Dawn Mercha</a>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EmployeeConatacts;