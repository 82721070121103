import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useHref, useNavigate, Navigate } from 'react-router-dom';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import Header from './Header'
import { contactsValidation } from './contactsValidation';

import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';

// import 'summernote/dist/summernote-lite.css';
// import 'summernote/dist/summernote-lite.js';

import 'datatables.net-bs4';
import { differenceInYears } from 'date-fns';


const Products = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // a local state to store the currently selected file.
    const [selectedYear, setSelectedYear] = useState(''); // State for selected year
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1980; // Define the starting year

     // Generate an array of years from startYear to currentYear
  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

  // Handle change in the dropdown
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };


    const [inputValue] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [date, setDate] = useState(new Date());
    const [contacts, setContacts] = useState([]);
    const [products, setProducts] = useState([]);
    const [assignees, setAssignees] = useState([]);


    const [selectedContact, setSelectedContact] = useState('');


    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [contactList, setContactList] = useState([]);
    const [data, setData] = useState([]);
    const [editProducts, setEditProducts] = useState([]);
    const [statusContact, setStatusContact] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [gender, setGender] = useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFileAdd, setSelectedFileAdd] = React.useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [image2, setImage2] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);

    const [hiddenEmpIdValue, setHiddenEmpIdValue] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formValues, setFormValues] = useState('');
    const modalRef = useRef(null);




    const navigate = useNavigate();

    $("#categorieslist_data_wrapper").off().on("click", '.edit-popup', function () {
        //alert('edit id')
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        const id = $(this).attr('data-id');
        handleEdit(id);
        return false;
    });

    const handleEdit = async (id) => {
        //alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/product-edit`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    setEditProducts(response.data.data);
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };


    // Event handler for status action
    $("#categorieslist_data").off().on("click", '.statusaction', function () {
        const statusActionId = $(this).attr('status-action-data-id');
        const statusAction = $(this).attr('status-action');
        // Confirmation dialog

        const userConfirmed = window.confirm("Are you sure you want to proceed?");

        if (userConfirmed) {
            try {
                handleStatusAction(statusActionId, statusAction);
            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


        return false;
    });


    const handleStatusAction = async (id, statusAction) => {
        if (id) {
            try {
                const response = await axios.post(`${apiUrl}/deals-status`, {
                    id: `${id}`,
                    statusAction: `${statusAction}`
                });

                // Update the specific row in the DataTable's data set without reloading
                if (response.data && response.data.data) {
                    updateDataTableRow(id, response.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };

    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/deals-contact-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setContacts(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);

    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/deals-product-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setProducts(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);



    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/deals-assignee-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setAssignees(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);




    // Contact List data
    useEffect(() => {

        axios.post(`${apiUrl}/product-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "pk_product_id" },
                                { title: "Product Name", data: "product_name" },
                                { title: "Model Number", data: "model_number" },
                                { title: "Machine Type", data: "machine_type" },
                                { title: "Year Of Manufacture", data: "year_of_manufacture" },
                                { title: "Serial Number", data: "serial_number" },
                                { title: "Specifications", data: "specifications" },
                               // { title: "Accessories Attachments Included", data: "accessories_attachments_included" },
                               // { title: "Electrical Requirements Id", data: "electrical_requirements_id" },
                               // { title: "Operational Specifications Id", data: "operational_specifications_id" },
                               // { title: "Control System", data: "control_system" },
                                //{ title: "Warranty Information Id", data: "warranty_information_id" },
                                { title: "Price", data: "price" },
                                {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        console.log("status============", statusContact);
                                        if (row.is_active == 1) {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.pk_product_id}" status-action="0"><span class='badge badge-pill badge-status bg-success'>Active</span></a>`;
                                        } else {
                                            return `<a href="javascript:void(0);" class="statusaction" status-action-data-id="${row.pk_product_id}" status-action="1"><span class='badge badge-pill badge-status bg-danger'>InActive</span></a>`;

                                        }
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                                        <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.pk_product_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                                        </div></div>`;


                                    }
                                },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    const handleGetValue = () => {
        // Access the value of the input element
        //alert('dddd');
    };



    const handleClick = (id, event) => {
        event.preventDefault();
        console.log('Clicked ID:', id);
        //alert('dddd');
        // Additional logic here
    };


    const handleOnSubmit = (values) => {
        const first_name = Object.keys(values)
            .map((key) => values[key])
            .join(" ");
        alert(`Hello ${first_name}!`);
    };





    const setSelectValue = (field, value) => {
        //alert(value);
        setFormValues({
            ...formValues,
            [field]: value,
        });
    };


    const handleImage2Change = (e) => {
        setImage2(e.target.files[0]);
    };



    //############# ADD FORM & VALIDATION START ########################################//

    const schema = Yup.object().shape({
        product_name: Yup.string().min(3).required('Product name is required'),
       
    });
    const formik = useFormik({
        initialValues: {
            product_name                       : '',
            model_number                       : '',
            machine_type                       : '',
            year_of_manufacture                : '',
            serial_number                      : '',
            specifications                     : '',
            accessories_attachments_included   : '',
            product_conditions                 : '',
            machine_description                : '',
            manufacturer                       : '',
            warranty_information_id            : '',
            price                              : '',
            payment_terms                      : '',
            discounts_and_offers               : '',
            location_of_the_machine            : '',
            shipping_options                   : '',
            shipping_cost                      : '',
            installation_services              : '',
            negotiable_price                   : '',
            support_and_maintenance            : '',
           


        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("product_name", values.product_name);
            formData.append("model_number", values.model_number);
            formData.append("machine_type", values.machine_type);
            formData.append("year_of_manufacture", values.year_of_manufacture);
            formData.append("serial_number", values.serial_number);
            formData.append("accessories_attachments_included", values.accessories_attachments_included);
            formData.append("product_conditions", values.product_conditions);
            formData.append("machine_description", values.machine_description);
            formData.append("manufacturer", values.manufacturer);
            formData.append("specifications", values.specifications);
            formData.append("price", values.price);
            formData.append("payment_terms", values.payment_terms);
            formData.append("discounts_and_offers", values.discounts_and_offers);
            formData.append("location_of_the_machine", values.location_of_the_machine);
            formData.append("shipping_options", values.shipping_options);
            formData.append("shipping_cost", values.shipping_cost);
            formData.append("installation_services", values.installation_services);
            formData.append("negotiable_price", values.negotiable_price);
            formData.append("support_and_maintenance", values.support_and_maintenance);


            axios({
                method: 'POST',
                headers: { "Content-Type": "multipart/form-data" },
                url: `${apiUrl}/product-csv-data-update`,
                data: formData
            })
                .then(function (res) {
                    console.log(res.status)
                    if (res.status === 200) {
                        pageLoadData();
                        //$('#create_contact').addClass('d-block');
                        alert('Successfully added data');

                        $('.toggle-popup').removeClass('sidebar-popup');
                        $('.sidebar-popoverlay').removeClass('open');
                    }

                })
                .catch(function (res) {
                    console.log(res)
                });


        }

    });




    const pageLoadData = async () => {
        axios.post(`${apiUrl}/product-list`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count
                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,
                            columns: [
                                { title: "Sr.No", data: "pk_product_id" },
                                { title: "Product Name", data: "product_name" },
                                { title: "Model Number", data: "model_number" },
                                { title: "Machine Type", data: "machine_type" },
                                { title: "Year Of Manufacture", data: "year_of_manufacture" },
                                { title: "Serial Number", data: "serial_number" },
                                { title: "Specifications", data: "specifications" },
                               // { title: "Accessories Attachments Included", data: "accessories_attachments_included" },
                               // { title: "Electrical Requirements Id", data: "electrical_requirements_id" },
                               // { title: "Operational Specifications Id", data: "operational_specifications_id" },
                               // { title: "Control System", data: "control_system" },
                                //{ title: "Warranty Information Id", data: "warranty_information_id" },
                                { title: "Price", data: "price" },
                                
                                {
                                    title: "Status",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                        return `<span class='badge badge-pill badge-status bg-success'>Active</span>`;
                                    }
                                },
                                {
                                    title: "Action",
                                    data: "null",
                                    render: function (data, type, row, meta) {

                                        return `<div class="dropdown table-action">
                              <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.pk_product_id}"><i class="ti ti-edit text-blue"></i> Edit</a>a class="dropdown-item change-pass" href="javascript:void(0);" change-pass-data-id="${row.pk_product_id}"><i class="ti ti-key text-danger"></i> Change Password</a>
                              </div></div>`;



                                    }
                                },

                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the contact list data!", error);
            });

    };






    const handleContactsNavigation = () => {
        navigate('/contacts');
    };

    const setInputValue = useCallback(
        (key, value) =>
            formik.setValues({
                ...formik.values,
                [key]: value,
            }),
        [formik]
    );

    const setInputValueEdit = useCallback(
        (key, value) =>
            edit_formik.setValues({
                ...edit_formik.values,
                [key]: value,
            }),
        [formik]
    );



    const handleChange = (event) => {
        alert(event.target.value);
        setInputValue(event.target.value);
    };







    //######################################################## Edit Section Here ###############################//

    useEffect(() => {
        // Initialize the gender state with the value from editProducts
        setGender(editProducts.gender);
    }, [editProducts]);

    useEffect(() => {
        // alert(editProducts.country_id)
        setSelectedValue(editProducts.country_id); // Dynamically set the default value
    }, []);


    useEffect(() => {

        if (editProducts) {
            setSelectedContact(editProducts.country_id);
            //setSelectedState(editProducts.state_id); 	 

            edit_formik.setValues({
                edit_pk_product_id: editProducts.pk_product_id || '',
                edit_pk_product_id: editProducts.pk_product_id || '',
                edit_product_name: editProducts.product_name || '',
                edit_model_number: editProducts.model_number || '',
                edit_machine_type: editProducts.machine_type || '',
                //edit_product_conditions: editProducts.product_conditions || '',
                edit_year_of_manufacture: editProducts.year_of_manufacture || '',

                edit_product_conditions: editProducts.product_conditions || '',
                edit_machine_description: editProducts.machine_description || '',
                edit_manufacturer: editProducts.manufacturer || '',
                edit_serial_number: editProducts.serial_number || '',
                edit_specifications: editProducts.specifications || '',
                edit_price: editProducts.price || '',
                edit_payment_terms: editProducts.payment_terms || '',
                edit_discounts_and_offers: editProducts.discounts_and_offers || '',
                edit_location_of_the_machine: editProducts.location_of_the_machine || '',
                edit_shipping_options: editProducts.shipping_options || '',

                edit_shipping_cost: editProducts.shipping_cost || '',
                edit_installation_services: editProducts.installation_services || '',
                edit_negotiable_price: editProducts.negotiable_price || '',
                edit_support_and_maintenance: editProducts.support_and_maintenance || '',
            });
        }
    }, [editProducts]);


    // Validation schema using Yup
    const edit_schema = Yup.object({
        edit_product_name: Yup.string().min(3).required('Product name is required'),
        edit_model_number: Yup.string().required('Model number is required'),
    });

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }


    // Formik setup
    const edit_formik = useFormik({
        initialValues: {
            edit_pk_product_id                  : '',
            edit_pk_product_id                  : '',
            edit_product_name                   : '',
            edit_model_number                   : '',
            edit_product_id                     : '',
            edit_machine_type                   : '',
            edit_year_of_manufacture            : '',
            edit_product_conditions             : '',
            edit_machine_description            : '',
            edit_manufacturer                   : '',
            edit_specifications                 : '',
            edit_serial_number                  : '',
            edit_price                          : '',
            edit_payment_terms                  : '',
            edit_discounts_and_offers           : '',
            edit_location_of_the_machine        : '',
            edit_shipping_cost                  : '',
            edit_installation_services          : '',
            edit_negotiable_price               : '',
            edit_support_and_maintenance        : '',
            edit_shipping_options               : '',
            

        },
        validationSchema: edit_schema,
        onSubmit: (values) => {
            console.log('Form submitted!');

            const formData = new FormData();
            formData.append("edit_pk_product_id", values.edit_pk_product_id);
            formData.append("edit_product_name", values.edit_product_name);
            formData.append("edit_model_number", values.edit_model_number);
            //formData.append("edit_product_id", values.edit_product_id);
            
            formData.append("edit_year_of_manufacture", values.edit_year_of_manufacture);
            formData.append("edit_product_conditions", values.edit_product_conditions);
            formData.append("edit_machine_description", values.edit_machine_description);
            formData.append("edit_manufacturer", values.edit_manufacturer);
            formData.append("edit_serial_number", values.edit_serial_number);
            formData.append("edit_specifications", values.edit_specifications);
            formData.append("edit_price", values.edit_price);
            formData.append("edit_payment_terms", values.edit_payment_terms);
            formData.append("edit_discounts_and_offers", values.edit_discounts_and_offers);
            formData.append("edit_location_of_the_machine", values.edit_location_of_the_machine);
            formData.append("edit_shipping_cost", values.edit_shipping_cost);
            formData.append("edit_installation_services", values.edit_installation_services);
            formData.append("edit_negotiable_price", values.edit_negotiable_price);
            formData.append("edit_support_and_maintenance", values.edit_support_and_maintenance);
            formData.append("edit_shipping_options", values.edit_shipping_options);
            
           

            axios({
                method: 'POST',
                url: `${apiUrl}/product-update`,
                headers: { "Content-Type": "multipart/form-data" },
                //data: values
                data: formData,
            })
                .then(function (res) {
                    console.log(res)
                    alert('Data was successfully updated.');
                    $('.toggle-popup1').removeClass('sidebar-popup');
                    $('.sidebar-popoverlay').removeClass('open');
                    editDataTableRow(values.edit_pk_product_id, res.data.data)
                    // $("#preview1").show();
                    setIsVisible(!isVisible); // Toggle visibility
                })
                .catch(function (res) {
                    console.log(res,)
                });

        },
    });


    const editDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };


    
    


    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Products<span className="count-title">{totalCount}</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="filter-section filter-flex">
                                        <div className="sortby-list">
                                            <ul>

                                                <li>
                                                    <div className="form-wrap icon-form">
                                                        <span className="form-icon"><i className="ti ti-calendar"></i></span>
                                                        <input type="text" className="form-control bookingrange" placeholder />
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div className="export-dropdwon">
                                                        <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                        <div className="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Products</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead classname="thead-light">
                                                <tr>
                                                    <th classname="no-sort">
                                                        <label classname="checkboxs">
                                                            <input type="checkbox" id="select-all" /><span classname="checkmarks"></span></label>
                                                    </th>
                                                    <th classname="no-sort"></th>
                                                    <th>Deal Name</th>
                                                    <th>Stage</th>
                                                    <th>Deal Value</th>
                                                    <th>Tags</th>
                                                    <th>Expected Close Date</th>
                                                    <th>Owner</th>
                                                    <th>Probability </th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="toggle-popup">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Add New Products</h4>
                        <a href="#" className="sidebar-close toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={formik.handleSubmit} >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Name <span className="text-danger">*</span></label>
                                            <input className="form-control" name="product_name" value={formik.values.product_name} onChange={(e) => setInputValue("product_name", e.target.value)} />
                                            <small className="error">{formik.errors.product_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Model Number <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="model_number" value={formik.values.model_number} onChange={(e) => setInputValue("model_number", e.target.value)} />
                                            <small className="error">{formik.errors.model_number}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Type</label>
                                            <input className="form-control" name="machine_type" value={formik.values.machine_type} onChange={(e) => setInputValue("machine_type", e.target.value)} />
                                            <small className="error">{formik.errors.machine_type}</small>
                                        </div>
                                    </div>
                                    

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Year Of Manufacture</label>
                                            {/* <input className="form-control" name="year_of_manufacture" value={formik.values.year_of_manufacture} onChange={(e) => setInputValue("year_of_manufacture", e.target.value)} /> */}

                                        <select
                                                className="form-control"
                                                id="year_of_manufacture"
                                                name="year_of_manufacture" // Add the name attribute for Formik
                                                value={formik.values.year_of_manufacture} // Bind to Formik's value
                                                onChange={formik.handleChange} // Use Formik's handleChange
                                        >
                                        <option value="">-- Select Year --</option>
                                        {years.map((year) => (
                                        <option key={year} value={year}>
                                        {year}
                                        </option>
                                        ))}
                                        </select>

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Conditions<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="product_conditions" value={formik.values.product_conditions} onChange={(e) => setInputValue("product_conditions", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Description</label>
                                            <input className="form-control" name="machine_description" value={formik.values.machine_description} onChange={(e) => setInputValue("machine_description", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Manufacturer</label>
                                            <input className="form-control" name="manufacturer" value={formik.values.manufacturer} onChange={(e) => setInputValue("manufacturer", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Serial Number </label>
                                            <input className="form-control" name="serial_number" value={formik.values.serial_number} onChange={(e) => setInputValue("serial_number", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Specifications </label>
                                            <input className="form-control" name="specifications" value={formik.values.specifications} onChange={(e) => setInputValue("specifications", e.target.value)} />
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Accessories Attachments Included</label>
                                            <input className="form-control" name="accessories_attachments_included" value={formik.values.accessories_attachments_included} onChange={(e) => setInputValue("accessories_attachments_included", e.target.value)} />
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Electrical Requirements_id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="electrical_requirements_id" value={formik.values.electrical_requirements_id} onChange={(e) => setInputValue("electrical_requirements_id", e.target.value)} />
                                        </div>
                                    </div> 

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Operational Specifications Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="operational_specifications_id" value={formik.values.operational_specifications_id} onChange={(e) => setInputValue("operational_specifications_id", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Control System<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="control_system" value={formik.values.control_system} onChange={(e) => setInputValue("control_system", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Manuals And Guides<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="manuals_and_guides" value={formik.values.manuals_and_guides} onChange={(e) => setInputValue("manuals_and_guides", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Certification Documents Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="certification_documents_id" value={formik.values.certification_documents_id} onChange={(e) => setInputValue("certification_documents_id", e.target.value)} />
                                        </div>
                                    </div>
                                    */}

                                     {/*<div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Serial Number</label>
                                            <input className="form-control" name="serial_number" value={formik.values.serial_number} onChange={(e) => setInputValue("serial_number", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Warranty Information Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="warranty_information_id" value={formik.values.warranty_information_id} onChange={(e) => setInputValue("warranty_information_id", e.target.value)} />
                                        </div>
                                    </div> */}

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Price</label>
                                            <input className="form-control" name="price" value={formik.values.price} onChange={(e) => setInputValue("price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Payment Terms</label>
                                            <input className="form-control" name="payment_terms" value={formik.values.payment_terms} onChange={(e) => setInputValue("payment_terms", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Discounts And Offers</label>
                                            <input className="form-control" name="discounts_and_offers" value={formik.values.discounts_and_offers} onChange={(e) => setInputValue("discounts_and_offers", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Location Of The Machine</label>
                                            <input className="form-control" name="location_of_the_machine" value={formik.values.location_of_the_machine} onChange={(e) => setInputValue("location_of_the_machine", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Options </label>
                                            <input className="form-control" name="shipping_options" value={formik.values.shipping_options} onChange={(e) => setInputValue("shipping_options", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Cost </label>
                                            <input className="form-control" name="shipping_cost" value={formik.values.shipping_cost} onChange={(e) => setInputValue("shipping_cost", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Installation Services </label>
                                            <input className="form-control" name="installation_services" value={formik.values.installation_services} onChange={(e) => setInputValue("installation_services", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Negotiable Price </label>
                                            <input className="form-control" name="negotiable_price" value={formik.values.negotiable_price} onChange={(e) => setInputValue("negotiable_price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Support And Maintenance</label>
                                            <input className="form-control" name="support_and_maintenance" value={formik.values.support_and_maintenance} onChange={(e) => setInputValue("support_and_maintenance", e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Create </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Product</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_pk_product_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_product_id} />

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Name <span className="text-danger">*</span></label>
                                            <input className="form-control" name="edit_product_name" value={edit_formik.values.edit_product_name} onChange={(e) => setInputValueEdit("edit_product_name", e.target.value)} />
                                            <small className="error">{edit_formik.errors.edit_product_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Model Number <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="edit_model_number" value={edit_formik.values.edit_model_number} onChange={(e) => setInputValueEdit("edit_model_number", e.target.value)} />
                                            <small className="error">{edit_formik.errors.edit_model_number}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Type </label>
                                            <input className="form-control" name="edit_machine_type" value={edit_formik.values.edit_machine_type} onChange={(e) => setInputValueEdit("edit_machine_type", e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Deal Value<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="deal_value" value={formik.values.deal_value} onChange={(e) => setInputValueEdit("deal_value", e.target.value)} />
                                        </div>
                                    </div> */}


                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Year Of Manufacture </label>
                                            {/* <input className="form-control" name="edit_year_of_manufacture" value={edit_formik.values.edit_year_of_manufacture} onChange={(e) => setInputValueEdit("edit_year_of_manufacture", e.target.value)} /> */}

                                            <select
                                                className="form-control"
                                                id="edit_year_of_manufacture"
                                                name="edit_year_of_manufacture" // Add the name attribute for Formik
                                                value={edit_formik.values.edit_year_of_manufacture} // Bind to Formik's value
                                                onChange={formik.handleChange} // Use Formik's handleChange
                                        >
                                        <option value="">-- Select Year --</option>
                                        {years.map((year) => (
                                        <option key={year} value={year}>
                                        {year}
                                        </option>
                                        ))}
                                        </select>

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Conditions</label>
                                            <input className="form-control" name="edit_product_conditions" value={edit_formik.values.edit_product_conditions} onChange={(e) => setInputValueEdit("edit_product_conditions", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Description</label>
                                            <input className="form-control" name="edit_machine_description" value={edit_formik.values.edit_machine_description} onChange={(e) => setInputValueEdit("edit_machine_description", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Manufacturer</label>
                                            <input className="form-control" name="edit_manufacturer" value={edit_formik.values.edit_manufacturer} onChange={(e) => setInputValueEdit("edit_manufacturer", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Serial Number</label>
                                            <input className="form-control" name="edit_serial_number" value={edit_formik.values.edit_serial_number} onChange={(e) => setInputValueEdit("edit_serial_number", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Specifications</label>
                                            <input className="form-control" name="edit_specifications" value={edit_formik.values.edit_specifications} onChange={(e) => setInputValueEdit("edit_specifications", e.target.value)} />
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Accessories Attachments Included<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="accessories_attachments_included" value={formik.values.accessories_attachments_included} onChange={(e) => setInputValueEdit("accessories_attachments_included", e.target.value)} />
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Electrical Requirements_id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="electrical_requirements_id" value={formik.values.electrical_requirements_id} onChange={(e) => setInputValueEdit("electrical_requirements_id", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Operational Specifications Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="operational_specifications_id" value={formik.values.operational_specifications_id} onChange={(e) => setInputValueEdit("operational_specifications_id", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Control System<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="control_system" value={formik.values.control_system} onChange={(e) => setInputValueEdit("control_system", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Manuals And Guides<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="manuals_and_guides" value={formik.values.manuals_and_guides} onChange={(e) => setInputValueEdit("manuals_and_guides", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Certification Documents Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="certification_documents_id" value={formik.values.certification_documents_id} onChange={(e) => setInputValueEdit("certification_documents_id", e.target.value)} />
                                        </div>
                                    </div> */}

                                   {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Serial Number</label>
                                            <input className="form-control" name="edit_serial_number" value={edit_formik.values.edit_serial_number} onChange={(e) => setInputValueEdit("edit_serial_number", e.target.value)} />
                                        </div>
                                    </div>

                                     <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Warranty Information Id<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="warranty_information_id" value={formik.values.warranty_information_id} onChange={(e) => setInputValueEdit("warranty_information_id", e.target.value)} />
                                        </div>
                                    </div> */}

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Price</label>
                                            <input className="form-control" name="edit_price" value={edit_formik.values.edit_price} onChange={(e) => setInputValueEdit("edit_price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Payment Terms</label>
                                            <input className="form-control" name="edit_payment_terms" value={edit_formik.values.edit_payment_terms} onChange={(e) => setInputValueEdit("edit_payment_terms", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Discounts And Offers</label>
                                            <input className="form-control" name="edit_discounts_and_offers" value={edit_formik.values.edit_discounts_and_offers} onChange={(e) => setInputValueEdit("edit_discounts_and_offers", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Location Of The Machine</label>
                                            <input className="form-control" name="edit_location_of_the_machine" value={edit_formik.values.edit_location_of_the_machine} onChange={(e) => setInputValueEdit("edit_location_of_the_machine", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Options </label>
                                            <input className="form-control" name="edit_shipping_options" value={edit_formik.values.edit_shipping_options} onChange={(e) => setInputValueEdit("edit_shipping_options", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Cost<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_shipping_cost" value={edit_formik.values.edit_shipping_cost} onChange={(e) => setInputValueEdit("edit_shipping_cost", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Installation Services<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_installation_services" value={edit_formik.values.edit_installation_services} onChange={(e) => setInputValueEdit("edit_installation_services", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Negotiable Price<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_negotiable_price" value={edit_formik.values.edit_negotiable_price} onChange={(e) => setInputValueEdit("edit_negotiable_price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Support And Maintenance<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_support_and_maintenance" value={edit_formik.values.edit_support_and_maintenance} onChange={(e) => setInputValueEdit("edit_support_and_maintenance", e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close1">Cancel</a>
                                    <button type="submit" className="btn btn-primary">
                                        Update
                                    </button>

                                    {/* <a href="#" className="btn btn-primary">Save Changes</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>






            <div className="modal custom-modal fade" id="create_contact" role="dialog" style={{ opacity: 1 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 m-0 justify-content-end">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-light-blue">
                                    <i className="ti ti-user-plus"></i>
                                </div>
                                <h3>Contact Created Successfully!!!</h3>
                                <p>View the details of contact, created</p>
                                <div className="col-lg-12 text-center modal-btn">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    {/* <a href="/contacts" re className="btn btn-primary">View Details</a> */}
                                    <button onClick={handleContactsNavigation} className="btn btn-primary">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal custom-modal fade" id="save_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New View</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap">
                                    <label className="col-form-label">View Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-danger">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" id="access_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Access For</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap icon-form">
                                    <span className="form-icon"><i className="ti ti-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>
                                <div className="access-wrap">
                                    <ul>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-19.jpg`} alt />
                                                    <a href="#">Darlee Robertson</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-20.jpg`} alt />
                                                    <a href="#">Sharon Roy</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-21.jpg`} alt />
                                                    <a href="#">Vaughan</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-01.jpg`} alt />
                                                    <a href="#">Jessica</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-16.jpg`} alt />
                                                    <a href="#">Carol Thomas</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs" >
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-22.jpg`} alt />
                                                    <a href="#">Dawn Mercha</a>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Products;