import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Home = () => {

   const apiUrl = process.env.REACT_APP_API_BASE_URL;
   //alert(apiUrl);
    console.log('API URL:', apiUrl);

   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [errors, setErrors] = useState({});
   const [loading, setLoading] = useState(false); // State to track loading
   const navigate = useNavigate();


   const validateForm = () => {
      const errors = {};
  
      if (!email) {
          errors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = "Email address is invalid";
      }
  
      if (!password) {
          errors.password = "Password is required";
      } else if (password.length < 6) {
          errors.password = "Password must be at least 6 characters";
      }
  
      return errors;
  };

  const handleSubmit = async (e) => {
   e.preventDefault();

   const validationErrors = validateForm();
   if (Object.keys(validationErrors).length > 0) {
       setErrors(validationErrors);
       return;
   }
   setLoading(true); // Start the loader

   try {
      // Ensure you're using the correct API URL
      const response = await axios.post(`${apiUrl}/login`, { email, password });
      //alert(response)
      
      console.log("Response received:", response);

      if (response.status === 200) {
          const { access_token, pk_emp_id, first_name, last_name, gender, phone, email, job_title, profile_img } = response.data.user;

          // Store data in local storage
          localStorage.setItem('tokenExpiration', Date.now() + response.data.expiresIn * 1000);
          localStorage.setItem('authToken', access_token);
          localStorage.setItem('emp_id', pk_emp_id);
          localStorage.setItem('first_name', first_name);
          localStorage.setItem('last_name', last_name);
          localStorage.setItem('gender', gender);
          localStorage.setItem('phone', phone);
          localStorage.setItem('email', email);
          localStorage.setItem('job_title', job_title);
          localStorage.setItem('profile_img', profile_img);

          console.log('Login successful:', response.data);
          navigate('/dashboard');
      }
  } catch (error) {
      // Improved error handling
      if (error.response) {
          // Server responded with a status code outside the range of 2xx
          console.error('Error response:', error.response.data);
          setErrors({ api: error.response.data.message || 'Invalid login credentials provided' });
      } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request);
          setErrors({ api: 'No response from server. Please try again later.' });
      } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
          setErrors({ api: 'An error occurred during login. Please try again.' });
      }
  } finally {
      setLoading(false); // Stop the loader regardless of success or failure
  }
   

};


   return (

      <div className="main-wrapper">
         <div className="account-content">
            <div className="login-wrapper account-bg">
               <div className="login-content">
                  <form onSubmit={handleSubmit}>
                     <div className="login-user-info">
                        <div className="login-logo">
                           <img src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} className="img-fluid" alt="Logo" />
                        </div>
                        <div className="login-heading">
                           <h4>Sign In</h4>
                           <p>Access the CRMS panel using your email and passcode.</p>
                        </div>

                        <div className="form-wrap">
                           <label className="col-form-label">Email Address</label>
                           <div className="form-wrap-icon">
                              <input type="email" className="pass-input form-control" value={email} onChange={(e) => setEmail(e.target.value)} />

                              <i className="ti ti-mail"></i>
                              {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                             
                           </div>
                        </div>
                        <div className="form-wrap">
                           <label className="col-form-label">Password</label>
                           <div className="pass-group">
                              <input type="password" className="pass-input form-control"
                                 id="username"
                                 value={password}
                                 onChange={(e) => setPassword(e.target.value)} />
                              <span className="ti toggle-password ti-eye-off"></span>
                              {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                           </div>
                        </div>
                        <div className="form-wrap form-wrap-checkbox">
                           <div className="custom-control custom-checkbox">
                              <label className="check">
                                 <input type="checkbox" />
                                 <span className="box"></span> Remember Me
                              </label>
                           </div>
                           <div className="text-end">
                              <a href="/" className="forgot-link">Forgot Password?</a>
                           </div>
                        </div>
                        <div className="form-wrap">
                        {errors.api && <p style={{ color: 'red' }}>{errors.api}</p>}
                           {/* <button type="submit" className="btn btn-primary">Sign In</button> */}
                           <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Logging in...' : 'Login'}</button>
                           {loading && <div className="loader">Loading...</div>}
                        </div>
                        <div className="login-form">
                           <h6>New on our platform?<a href="/" className="hover-a"> Create an account</a></h6>
                        </div>
                        {/*<div className="form-set-login or-text">
                           <h4>OR</h4>
                        </div>
                        <div className="login-social-link">
                           <ul className="nav">
                              <li>
                                 <a href="/" className="facebook-logo">
                                 <img src={"./assets/img/icons/facebook-logo.svg"} alt="Facebook" />
                                 </a>
                              </li>
                              <li>
                                 <a href="/">
                                 <img src={`${process.env.PUBLIC_URL}/assets/img/icons/google-logo.svg`} alt="Google" />
                                 </a>
                              </li>
                              <li>
                                 <a href="/" className="apple-logo">
                                 <img src={`${process.env.PUBLIC_URL}/assets/img/icons/apple-logo.svg`} alt="Google" />
                                 </a>
                              </li>
                           </ul>
                           <div className="copyright-text">
                              <p>Copyright &copy;2024 - CRMS</p>
                           </div>
                        </div>*/}
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Home